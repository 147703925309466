import React, {useEffect, useState, useCallback, useContext} from 'react';
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import 'dayjs/locale/es'
import {Chart as ChartJS, CategoryScale, LinearScale, ArcElement, BarElement,  PointElement, LineElement, Title, Tooltip, Legend, Filler} from 'chart.js';
import {Line, Bar} from 'react-chartjs-2';
import '../styles/data.css';
import Empty from '../utils/Empty';
import DataRangeActivity from '../blocks/DataRangeActivity';
import DataChallenges from '../blocks/DataChallenges';
import DataMetricActivity from '../blocks/DataMetricActivity';
import DataMetricHr from '../blocks/DataMetricHr';
import DataChallenge from '../blocks/DataChallenge';
import DataCalendar from '../blocks/DataCalendar';
import DataSleep from '../blocks/DataSleep';
import DataActivity from '../blocks/DataActivity';
import {NetworkContext} from "../utils/NetworkContext";
ChartJS.register(ArcElement, CategoryScale, LinearScale, BarElement, PointElement, LineElement, Title, Tooltip, Legend, Filler);

function Data({locale, navigation, Navigation, user, width, configuration, date, setDate, setToast, toast, dialog, Dialog}) {
    const {restCall, restResponse, setRestResponse} = useContext(NetworkContext);
    const [challenge, setChallenge] = useState({});
    const [sleep, setSleep] = useState({});
    const [activity, setActivity] = useState({});
    const [chartData, setChartData] = useState({});
    const [chartOptions, setChartOptions] = useState({});
    const [mobiMonth, setMobiMonth] = useState([]);
    const [mobiYear, setMobiYear] = useState([]);

    const Activity = () => {
        setToast({message: locale.data.s, color: 'info', display: 'bottom', duration: 1000});

        const message = {
            type: 'rest',
            path: 'activity',
            action: 'get',
            data: {
                date: date,
                range: navigation.tab,
                metric: navigation.item,
                block: 'data'
            }
        };
        restCall(message);
    }

    const GetActivity = useCallback(data => {
        if (navigation.item === 'steps' && data.chartData && !data.mobiMonth && !data.mobiYear) {
            data.chartData.datasets[0].label = locale.data.a;
        }
        if (navigation.item === 'calories' && data.chartData && !data.mobiMonth && !data.mobiYear) {
            data.chartData.datasets[0].label = locale.data.c;
        }
        if (navigation.item === 'distance' && data.chartData && user.unit === 'metric' && !data.mobiMonth && !data.mobiYear) {
            data.chartData.datasets[0].label = locale.data.b + ' in ' + locale.data.d;
        }
        if (navigation.item === 'distance' && data.chartData && user.unit === 'imperial' && !data.mobiMonth && !data.mobiYear) {
            data.chartData.datasets[0].label = locale.data.b + ' in ' + locale.data.m;
        }
        if (navigation.tab === 'month' && data.mobiMonth) {
            setMobiMonth(data.mobiMonth);
        }
        if (navigation.tab === 'month' && data.chartData && (data.mobiMonth || data.mobiYear)) {
            data.chartData.datasets[0].label = locale.data.w;
            data.chartData.labels = configuration.chart.data.month;
        }
        if (navigation.tab === 'year' && data.mobiYear) {
            setMobiYear(data.mobiYear);
        }
        if (navigation.tab === 'year' && data.chartData && !data.mobiMonth && !data.mobiYear) {
            data.chartData.labels = locale.data.v;
        }
        if (navigation.tab === 'year' && data.chartData && (data.mobiMonth || data.mobiYear)) {
            data.chartData.datasets[0].label = locale.data.x;
            data.chartData.labels = locale.data.z;
        }
        if (navigation.tab === 'week' && data.chartData && !data.mobiMonth && !data.mobiYear) {
            data.chartData.labels = locale.data.u;
            const item = configuration.chart.activity.week;
            item.plugins.title.text = data.text;
            setChartOptions(item);
        }
        if (navigation.tab !== 'week' && !data.mobiMonth && !data.mobiYear) {
            setChartOptions(configuration.chart.activity.options);
        }
        if (data.chartData) {
            setChartData(data.chartData);
        }
        if (data.mobiMonth || data.mobiYear) {
            setActivity({
                steps: data.total,
                calories: 0,
                distance: 0
            });
        }
        if (navigation.item === 'steps' && (data.total || data.total === 0) && !data.mobiMonth && !data.mobiYear) {
            setActivity({
                steps: data.total,
                calories: 0,
                distance: 0
            });
        }
        if (navigation.item === 'calories' && (data.total || data.total === 0) && !data.mobiMonth && !data.mobiYear) {
            setActivity({
                steps: 0,
                calories: data.total,
                distance: 0
            });
        }
        if (navigation.item === 'distance' && (data.total || data.total === 0) && !data.mobiMonth && !data.mobiYear) {
            setActivity({
                steps: 0,
                calories: 0,
                distance: data.total
            });
        }
    }, [locale.data.u, locale.data.v, locale.data.w, locale.data.x, locale.data.z, configuration.chart.data.month, configuration.chart.activity.options, configuration.chart.activity.week, locale.data.m, locale.data.a, locale.data.b, locale.data.c, locale.data.d, navigation.item, navigation.tab, user.unit]);

    const GetHeart = useCallback(data => {
        if (navigation.item === 'hr' && data.chartData && data.chartData.datasets.length === 1) {
            data.chartData.datasets[0].label = locale.data.e;
            setChartData(data.chartData);
        }
        if (navigation.item === 'hr' && data.chartData && data.chartData.datasets.length === 5) {
            data.chartData.datasets[0].label = locale.data.k;
            data.chartData.datasets[1].label = locale.data.j;
            data.chartData.datasets[2].label = locale.data.i;
            data.chartData.datasets[3].label = locale.data.h;
            data.chartData.datasets[4].label = locale.data.l;
            setChartData(data.chartData);
        }
        if (navigation.item === 'pressure' && data.chartData && data.chartData.datasets.length === 2) {
            data.chartData.datasets[0].label = locale.data.f;
            data.chartData.datasets[1].label = locale.data.g;
            setChartData(data.chartData);
        }
    }, [navigation.item, locale.data.k, locale.data.j, locale.data.i, locale.data.h, locale.data.l, locale.data.e, locale.data.f, locale.data.g]);

    const GetSleep = useCallback(data => {
        if (data.chartData) {
            data.chartData.datasets[0].label = locale.data.n;
            data.chartData.datasets[1].label = locale.data.o;
            data.chartData.datasets[2].label = locale.data.p;
            data.chartData.datasets[3].label = locale.data.q;
            data.chartData.datasets[4].label = locale.data.r;
            setChartData(data.chartData);
        }
        if (data.sleep) {
            setSleep(data.sleep);
        }
    }, [locale.data.n, locale.data.o, locale.data.p, locale.data.q, locale.data.r]);

    useEffect(() => {
        if (!Empty(restResponse) && restResponse.action === 'get' && restResponse.path === 'activity') {
            GetActivity(restResponse.data);
            setRestResponse({});
        }
    }, [restResponse, GetActivity, setRestResponse]);

    useEffect(() => {
        if (!Empty(restResponse) && restResponse.action === 'get' && restResponse.path === 'heart') {
            GetHeart(restResponse.data);
            setRestResponse({});
        }
    }, [restResponse, GetHeart, setRestResponse]);

    useEffect(() => {
        if (!Empty(restResponse) && restResponse.action === 'get' && restResponse.path === 'sleep') {
            GetSleep(restResponse.data);
            setRestResponse({});
        }
    }, [restResponse, GetSleep, setRestResponse]);

    useEffect(() => {
        Activity();
    }, []);

  return (
      <div className={width > 768 ? "mbsc-grid cs-data-grid" : "mbsc-grid cs-data-grid-small"}>
          {navigation.metric === 'activity' &&
              <DataRangeActivity
                  locale={locale}
                  navigation={navigation}
                  Navigation={Navigation}
                  width={width}
                  setDate={setDate}
                  setToast={setToast}
              />
          }
          {navigation.metric === 'challenge' &&
              <DataChallenges
                  locale={locale}
                  navigation={navigation}
                  Navigation={Navigation}
                  setDate={setDate}
                  setToast={setToast}
                  Dialog={Dialog}
                  dialog={dialog}
                  width={width}
                  setChartData={setChartData}
                  setChallenge={setChallenge}
              />
          }
          {(navigation.metric === 'activity' || navigation.metric === 'sleep' || navigation.metric === 'hr') &&
              <DataCalendar
                  user={user}
                  setDate={setDate}
                  navigation={navigation}
                  mobiMonth={mobiMonth}
                  mobiYear={mobiYear}
                  locale={locale}
                  date={date}
                  setToast={setToast}
                  toast={toast}
              />
          }
          {navigation.metric === 'activity' && !Empty(chartData) &&
              <div className="mbsc-row cs-data-block-main">
                  <Line options={chartOptions} data={chartData} />
              </div>
          }
          {(navigation.metric === 'hr' || navigation.metric === 'sleep') && !Empty(chartData) &&
              <div className="mbsc-row cs-data-block-main">
                  <Bar options={configuration.chart.hr.options} data={chartData} />
              </div>
          }
          {!Empty(challenge) && navigation.metric === 'challenge' &&
              <DataChallenge
                  locale={locale}
                  challenge={challenge}
                  user={user}
                  navigation={navigation}
                  chartData={chartData}
                  configuration={configuration}
                  setToast={setToast}
                  width={width}
                  Navigation={Navigation}
                  setDate={setDate}
              />
          }
          {navigation.metric === 'activity' &&
              <DataMetricActivity
                  locale={locale}
                  navigation={navigation}
                  Navigation={Navigation}
                  date={date}
                  setToast={setToast}
              />
          }
          {navigation.metric === 'hr' &&
              <DataMetricHr
                  locale={locale}
                  navigation={navigation}
                  Navigation={Navigation}
                  date={date}
                  setToast={setToast}
              />
          }
          {navigation.metric === 'activity' && !Empty(activity) &&
              <DataActivity
                  locale={locale}
                  user={user}
                  activity={activity}
                  navigation={navigation}
              />
          }
          {navigation.metric === 'sleep' && !Empty(sleep) &&
              <DataSleep
                  locale={locale}
                  sleep={sleep}
              />
          }
      </div>
  );
}

export default Data;
