import React, {useCallback, useContext, useEffect, useState} from 'react';
import {Popup, Select, toast} from "@mobiscroll/react";
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import '../styles/headerLocale.css';
import Empty from '../utils/Empty';
import {NetworkContext} from "../utils/NetworkContext";

function HeaderLocale({dialog, configuration, Dialog, user, setUser, setLocale, locale}) {
  const {restCall, restResponse, setRestResponse} = useContext(NetworkContext);
  const Language = () => {
    if (!Empty(user)){
      return user.locale;
    } else if (window.localStorage.locale) {
      return window.localStorage.locale;
    } else {
      window.localStorage.locale = 'en';
      return 'en';
    }
  }

  const [language, setLanguage] = useState(Language());

  const CloseDialog = useCallback(() => {
    Dialog({view: 'close'});
  }, [Dialog]);

  const Locale = data => {
    if (data === 'es') {
      setLocale(configuration.locale.es);
      setLanguage('es');
      window.localStorage.locale = 'es';
    } else if (data === 'en') {
      setLocale(configuration.locale.en);
      setLanguage('en');
      window.localStorage.locale = 'en';
    }
    if (!Empty(user) && user.locale !== data) {

      toast({message: locale.headerLocale.a, color: 'info', display: 'bottom', duration: 1000});

      const item = {...user};
      item.locale = data;
      setUser(item);

      const message = {
        type: 'rest',
        path: 'user',
        action: 'update',
        data: {
          locale: data
        }
      };
      restCall(message);
    }
  }

  const UpdateUser = useCallback(data => {
    if (data.toast && dialog.view === 'headerLocale') {
      toast({message: locale.headerLocale.b, color: 'info', display: 'bottom', duration: 1000});
      CloseDialog();
    }
  }, [locale.headerLocale.b, CloseDialog, dialog.view]);

  useEffect(() => {
    if (!Empty(restResponse) && restResponse.action === 'update' && restResponse.path === 'user') {
      UpdateUser(restResponse.data);
      setRestResponse({});
    }
  }, [restResponse, UpdateUser, setRestResponse]);

  return (
      <Popup className="cs-header-locale-popup" theme="ios" themeVariant="light" display="anchored" anchor={document.getElementById('headerLocale')} buttons={[]} isOpen={dialog.view === 'headerLocale'} onClose={CloseDialog}>
        <Select
            theme="ios"
            themeVariant="light"
            rows={2}
            display="inline"
            touchUi={false}
            selectMultiple={false}
            value={language}
            data={configuration.mobi.language}
            onChange={(event) => Locale(event.value)}
        />
      </Popup>
  );
}

export default HeaderLocale;
