import React, {useCallback, useContext, useEffect} from 'react';
import {Button, confirm, toast as mobiToast} from "@mobiscroll/react";
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import '../styles/homeHeader.css';
import Empty from '../utils/Empty';
import {faRankingStar, faWatchSmart, faMoonCloud, faHeartPulse, faShoePrints, faLanguage, faMessageQuestion, faRightFromBracket, faPipe} from "@fortawesome/pro-duotone-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {NetworkContext} from "../utils/NetworkContext";
import HeaderLocale from '../dialogs/HeaderLocale';

function HomeHeader({Navigation, Logout, locale, navigation, displayName, user, dialog, Dialog, configuration, width, setLocale, setUser, date, toast, devices, setDevices, setRefresh, setToast}) {
    const {restCall, restResponse, setRestResponse} = useContext(NetworkContext);

    const Activity = data => {
        setToast({message: locale.homeHeader.p, color: 'info', display: 'bottom', duration: 1000});
        Navigation(data);

        const message = {
            type: 'rest',
            path: 'activity',
            action: 'get',
            data: {
                date: date,
                range: data.tab,
                metric: data.item,
                block: 'homeHeader'
            }
        };
        restCall(message);
    }

    const Device = data => {
        setToast({message: locale.homeHeader.i, color: 'info', display: 'bottom', duration: false});

        navigator.bluetooth.requestDevice({ filters: [{services: [configuration.ble.service]}] }).then(data => {
            const itemName = data.name;
            const itemId = data.id;

            const message = {
                type: 'rest',
                path: 'device',
                action: 'get',
                data: {
                    name: itemName,
                    id: itemId
                }
            };
            restCall(message);
        }).catch(error => {
            mobiToast({message: locale.homeHeader.j, color: 'danger', display: 'bottom', duration: 3000});
        });
    }

    const HR = data => {
        setToast({message: locale.homeHeader.p, color: 'info', display: 'bottom', duration: 1000});
        Navigation(data);

        const message = {
            type: 'rest',
            path: 'hr',
            action: 'get',
            data: {
                date: date,
                metric: data.item,
                block: 'homeHeader'
            }
        };
        restCall(message);
    }

    const Sleep = data => {
        setToast({message: locale.homeHeader.p, color: 'info', display: 'bottom', duration: 1000});
        Navigation(data);

        const message = {
            type: 'rest',
            path: 'sleep',
            action: 'get',
            data: {
                date: date,
                block: 'homeHeader'
            }
        };
        restCall(message);
    }

    const GetDevice = useCallback(data => {
        if (data.error) {
            setToast({message: locale.homeHeader.k, color: 'danger', display: 'bottom', duration: 3000});
        }
        if (data.toast) {
            const text = data.name + locale.homeHeader.m;

            confirm({
                title: locale.homeHeader.l,
                message: text,
                okText: locale.homeHeader.l,
                cancelText: locale.homeHeader.n,
                callback: (res) => {
                    if (res) {
                        setRefresh(true);

                        const message = {
                            type: 'rest',
                            path: 'device',
                            action: 'update',
                            data: {
                                name: data.name,
                                id: data.id
                            }
                        };
                        restCall(message);
                    }
                }
            });
        }
    }, [restCall, locale.homeHeader.k, locale.homeHeader.m, locale.homeHeader.l, locale.homeHeader.n]);

    const UpdateDevice = useCallback(data => {
        if (data.device) {
            const array = devices;
            array.push({data: data.device});
            setDevices(array);

            setRefresh(false);
        }
        if (data.toast) {
            setToast({message: locale.homeHeader.o, color: 'info', display: 'bottom', duration: 3000});
        }
    }, [devices, locale.homeHeader.o]);

    useEffect(() => {
        if (!Empty(restResponse) && restResponse.action === 'get' && restResponse.path === 'device') {
            GetDevice(restResponse.data);
            setRestResponse({});
            setToast({});
        }
    }, [restResponse, GetDevice, setRestResponse]);

    useEffect(() => {
        if (!Empty(restResponse) && restResponse.action === 'update' && restResponse.path === 'device') {
            UpdateDevice(restResponse.data);
            setRestResponse({});
        }
    }, [restResponse, UpdateDevice, setRestResponse]);

    return (
        <div className="mbsc-grid cs-home-header-toolbar">
            <div className="mbsc-row">
                <div className={width > 768 ? 'mbsc-col' : 'mbsc-col cs-home-header-col-xs'}>
                    <div className="mbsc-row">
                        {width > 576 &&
                            <div className="cs-home-header-logo-water" />
                        }
                        {width > 992 &&
                            <Button color="primary" variant="flat" theme="ios" themeVariant="light" className="cs-home-header-display mbsc-bold" >
                                {displayName}
                            </Button>
                        }
                    </div>
                </div>
                <div className={width > 768 ? 'mbsc-col-auto' : 'mbsc-col-auto cs-home-header-col-xs'}>
                    <div className="mbsc-row mbsc-justify-content-end">
                        {navigation.page === 'data' &&
                            <Button color="primary" variant="flat" theme="ios" themeVariant="light" title={locale.homeHeader.a} className="mbsc-bold" disabled={navigation.metric === 'activity'} onClick={() => Activity({metric:'activity', item:'steps', tab:'day'})} >
                                <FontAwesomeIcon className={navigation.metric === 'activity' ? 'cs-home-header-navigation-disabled' : 'cs-home-header-navigation-active'} icon={faShoePrints} /> {width > 768 && <div className="cs-home-header-menu-text">{locale.homeHeader.a}</div>}
                            </Button>
                        }
                        {navigation.page === 'data' &&
                            <Button color="primary" variant="flat" theme="ios" themeVariant="light" title={locale.homeHeader.b} className="mbsc-bold" disabled={navigation.metric === 'hr'} onClick={() => HR({metric:'hr', item:'hr', tab:'day'})} >
                                <FontAwesomeIcon className={navigation.metric === 'hr' ? 'cs-home-header-navigation-disabled' : 'cs-home-header-navigation-active'} icon={faHeartPulse} /> {width > 768 && <div className="cs-home-header-menu-text">{locale.homeHeader.b}</div>}
                            </Button>
                        }
                        {navigation.page === 'data' &&
                            <Button color="primary" variant="flat" theme="ios" themeVariant="light" title={locale.homeHeader.c} className="mbsc-bold" disabled={navigation.metric === 'sleep'} onClick={() => Sleep({metric:'sleep', tab:'day'})} >
                                <FontAwesomeIcon className={navigation.metric === 'sleep' ? 'cs-home-header-navigation-disabled' : 'cs-home-header-navigation-active'} icon={faMoonCloud} /> {width > 768 && <div className="cs-home-header-menu-text">{locale.homeHeader.c}</div>}
                            </Button>
                        }
                        {navigation.page === 'data' &&
                            <Button color="primary" variant="flat" theme="ios" themeVariant="light" title={locale.homeHeader.d} className="mbsc-bold" disabled={navigation.metric === 'challenge'} onClick={() => Navigation({metric:'challenge'})} >
                                <FontAwesomeIcon className={navigation.metric === 'challenge' ? 'cs-home-header-navigation-disabled' : 'cs-home-header-navigation-active'} icon={faRankingStar} /> {width > 768 && <div className="cs-home-header-menu-text">{locale.homeHeader.d}</div>}
                            </Button>
                        }
                        {navigation.page === 'profile' &&
                            <Button color="primary" variant="flat" theme="ios" themeVariant="light" title={locale.homeHeader.e} className="mbsc-bold" disabled={navigation.metric === 'help'} onClick={() => Navigation({page: 'help'})} >
                                <FontAwesomeIcon className={navigation.metric === 'help' ? 'cs-home-header-navigation-disabled' : 'cs-home-header-navigation-active'} icon={faMessageQuestion} /> {width > 768 && <div className="cs-home-header-menu-text">{locale.homeHeader.e}</div>}
                            </Button>
                        }
                        {navigation.page === 'profile' &&
                            <Button color="primary" variant="flat" theme="ios" themeVariant="light" title={locale.homeHeader.f} className="mbsc-bold" onClick={() => Logout()} >
                                <FontAwesomeIcon className="cs-home-header-navigation-logout" icon={faRightFromBracket} /> {width > 768 && <div className="cs-home-header-menu-text">{locale.homeHeader.f}</div>}
                            </Button>
                        }
                        {navigation.page === 'device' &&
                            <Button color="primary" variant="flat" theme="ios" themeVariant="light" title={locale.homeHeader.g} className="mbsc-bold" disabled={!Empty(toast)} onClick={() => Device()} >
                                <FontAwesomeIcon className={!Empty(toast) ? 'cs-home-header-navigation-disabled' : 'cs-home-header-navigation-active'} icon={faWatchSmart} /> {width > 768 && <div className="cs-home-header-menu-text">{locale.homeHeader.g}</div>}
                            </Button>
                        }
                        <Button color="secondary" variant="flat" theme="ios" themeVariant="light" >
                            <FontAwesomeIcon className="cs-home-header-button-icon-pipe" icon={faPipe} />
                        </Button>
                        <Button color="primary" variant="flat" theme="ios" themeVariant="light" id="headerLocale" title={locale.homeHeader.h} className="mbsc-bold cs-home-header-button-menu" disabled={!Empty(toast)} onClick={() => Dialog({view: 'headerLocale'})} >
                            <FontAwesomeIcon className={!Empty(toast) ? 'cs-home-header-navigation-disabled' : 'cs-home-header-navigation-active'} icon={faLanguage} />
                        </Button>
                    </div>
                </div>
            </div>
            {dialog.view === 'headerLocale' &&
                <HeaderLocale
                    dialog={dialog}
                    configuration={configuration}
                    Dialog={Dialog}
                    user={user}
                    setUser={setUser}
                    setLocale={setLocale}
                    locale={locale}
                />
            }
        </div>
    );
}

export default HomeHeader;
