import React, {useCallback, useContext, useState} from 'react';
import {Popup, Input, toast} from "@mobiscroll/react";
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import '../styles/loginReset.css';
import {NetworkContext} from "../utils/NetworkContext";

function LoginReset({dialog, Dialog, setEmail, locale, email, mobile, setMobile, username, setUsername, type, reset, account}) {
  const {restCall} = useContext(NetworkContext);
  const [password, setPassword] = useState('');
  const [confirm, setConfirm] = useState('');

  const CloseDialog = useCallback(() => {
    reset.current = '';
    setPassword('');
    setConfirm('');
    setEmail('');
    setUsername('');
    setMobile('');
    Dialog({view: 'close'});
  }, [Dialog]);

  const Auth = () => {
    if (!password || !confirm) {
      toast({message: locale.loginReset.e, color: 'danger', display: 'bottom', duration: 3000});
    } else if (password !== confirm) {
      toast({message: locale.loginReset.f, color: 'danger', display: 'bottom', duration: 3000});
    } else {
      const item = {
        password: password,
        reset: reset.current,
        account: account
      }
      if (type === 'email') {
        item.email = email;
      } else if (type === 'mobile') {
        item.mobile = mobile;
      } else if (type === 'username') {
        item.username = username;
      }

      const message = {
        type: 'rest',
        path: 'token',
        action: 'get',
        data: item
      };
      restCall(message);
    }
  }

  return (
      <Popup className="cs-login-reset-popup" width={600} theme="ios" themeVariant="light" display="center" headerText={locale.loginReset.g} buttons={[{text: locale.loginReset.a, cssClass: 'cs-login-reset-cancel', handler: function (event) {CloseDialog()}},{text: locale.loginReset.b, cssClass: 'cs-login-reset-save', handler: function (event) {Auth()}}]} isOpen={dialog.view === 'passwordReset'} onClose={CloseDialog}>
        <Input theme="ios" themeVariant="light" inputStyle="underline" label={locale.loginReset.c} labelStyle="floating" type="password" name="password" passwordToggle={true} value={password} onChange={ev => setPassword(ev.target.value)} />
        <Input theme="ios" themeVariant="light" inputStyle="underline" label={locale.loginReset.d} labelStyle="floating" type="password" name="confirm" passwordToggle={true} value={confirm} onChange={ev => setConfirm(ev.target.value)} />
      </Popup>
  );
}

export default LoginReset;
