import React from 'react';
import {Button} from "@mobiscroll/react";
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faShoePrints, faMapLocationDot, faFireFlame} from "@fortawesome/pro-duotone-svg-icons";
import '../styles/dataActivity.css';

function DataActivity({user, locale, activity, navigation}) {

  const Distance = data => {
    if (user.unit === 'metric') {
      return data
    } else {
      return data.toFixed(2);
    }
  }

  return (
      <div className="mbsc-row cs-data-activity-main mbsc-justify-content-around">
          <Button color={navigation.item === 'steps' ? "primary" : "secondary"} variant="flat" theme="ios" themeVariant="light" className="mbsc-bold cs-data-activity-cursor" >
              <FontAwesomeIcon className={navigation.item === 'steps' ? "cs-data-activity-steps" : "cs-data-activity-disabled"} icon={faShoePrints} /><span className={navigation.item === 'steps' ? "cs-data-activity-text" : "cs-data-activity-text-disabled" }><h4>{navigation.item === 'steps' && activity.steps} {locale.dataActivitya}</h4></span>
          </Button>
          <Button color={navigation.item === 'distance' ? "success" : "secondary"} variant="flat" theme="ios" themeVariant="light" className="mbsc-bold cs-data-activity-cursor" >
              <FontAwesomeIcon className={navigation.item === 'distance' ? "cs-data-activity-distance" : "cs-data-activity-disabled"} icon={faMapLocationDot} /><span className={navigation.item === 'distance' ? "cs-data-activity-text" : "cs-data-activity-text-disabled" }><h4>{navigation.item === 'distance' && Distance(activity.distance)} {user.unit === 'metric' ? locale.dataActivityb : locale.dataActivityc}</h4></span>
          </Button>
          <Button color={navigation.item === 'calories' ? "danger" : "secondary"} variant="flat" theme="ios" themeVariant="light" className="mbsc-bold cs-data-activity-cursor" >
              <FontAwesomeIcon className={navigation.item === 'calories' ? "cs-data-activity-calories" : "cs-data-activity-disabled"} icon={faFireFlame} /><span className={navigation.item === 'calories' ? "cs-data-activity-text" : "cs-data-activity-text-disabled"}><h4>{navigation.item === 'calories' && activity.calories} {locale.dataActivityd}</h4></span>
          </Button>
      </div>
  );
}

export default DataActivity;
