import React, {useCallback, useState, useEffect, useContext} from 'react';
import {SegmentedGroup, Segmented, Select, Input, Button, alert, toast} from "@mobiscroll/react";
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import './styles/login.css';
import {faWatchFitness} from "@fortawesome/pro-duotone-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Empty from './utils/Empty';
import English from './locale/english.json';
import Spanish from './locale/spanish.json';
import LoginReset from "./dialogs/LoginReset";
import {NetworkContext} from './utils/NetworkContext';

const LoginType = () => {
    const item = window.localStorage.login;
    if (!item) {
        window.localStorage.login = 'email'
        return 'email';
    } else {
        return item;
    }
}

const Country = () => {
    const item = window.localStorage.country;
    if (!item) {
        window.localStorage.country = 'us';
        return 'us';
    } else {
        return item;
    }
}

function Login({token, logo, setOptions, displayName, configuration, setConfiguration, account, locale, Navigation, setNavigation, dialog, setDialog, Dialog, reset, setLocale}) {
    const {restCall, restResponse, setRestResponse} = useContext(NetworkContext);
    const [country, setCountry] = useState(Country());
    const [email, setEmail] = useState('');
    const [mobile, setMobile] = useState('');
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [next, setNext] = useState(true);
    const [type, setType] = useState(LoginType());

    const Auth = () => {
        if (!password) {
            toast({message: locale.login.q, color: 'danger', display: 'bottom', duration: 3000});
        } else {
            const item = {
                account: account.current,
                password: password,
                type: type
            }
            if (type === 'email') {
                item.id = email;
            } else if (type === 'mobile') {
                item.id = mobile.replace(/[()\s-]/g, '');
                item.country = country;
            } else if (type === 'username') {
                item.id = username;
            }

            const message = {
                type: 'rest',
                path: 'token',
                action: 'get',
                data: item
            };
            restCall(message);
        }
    }

    const Next = () => {
        if (type === 'email' && !email) {
            toast({message: locale.login.g, color: 'danger', display: 'bottom', duration: 3000});
        } else if (type === 'mobile' && !mobile) {
            toast({message: locale.login.x, color: 'danger', display: 'bottom', duration: 3000});
        } else if (type === 'username' && !username) {
            toast({message: locale.login.y, color: 'danger', display: 'bottom', duration: 3000});
        } else {
            const item = {
                account: account.current,
                type: type
            }
            if (type === 'email') {
                item.id = email;
            } else if (type === 'mobile') {
                item.id = mobile.replace(/[()\s-]/g, '');
                item.country = country;
            } else if (type === 'username') {
                item.id = username;
            }

            const message = {
                type: 'rest',
                path: 'user',
                action: 'get',
                data: item
            };
            restCall(message);
        }
    }

    const Type = data => {
        setType(data.target.value);
        window.localStorage.login = data.target.value;
    }

    const Code = data => {
        window.localStorage.country = data;
        setCountry(data);
    }

    const Mobile = data => {
        let formattedText = '';

        if (country === 'us') {
            const cleanedText = data.replace(/[^\d]/g, '');

            if (cleanedText.length <= 3) {
                formattedText = cleanedText;
            } else if (cleanedText.length <= 6) {
                formattedText = `(${cleanedText.slice(0, 3)}) ${cleanedText.slice(3)}`;
            } else {
                formattedText = `(${cleanedText.slice(0, 3)}) ${cleanedText.slice(3, 6)}-${cleanedText.slice(6, 10)}`;
            }
        } else if (country === 'mx') {
            const cleanedText = data.replace(/[^\d]/g, '');

            if (cleanedText.length <= 2) {
                formattedText = cleanedText;
            } else if (cleanedText.length <= 6) {
                formattedText = `(${cleanedText.slice(0, 2)}) ${cleanedText.slice(2)}`;
            } else {
                formattedText = `(${cleanedText.slice(0, 2)}) ${cleanedText.slice(2, 6)}-${cleanedText.slice(6, 10)}`;
            }
        }
        setMobile(formattedText);
    }

    const Forgot = () => {
        if (type === 'email' && !email) {
            toast({message: locale.login.g, color: 'danger', display: 'bottom', duration: 3000});
        } else if (type === 'mobile' && !mobile) {
            toast({message: locale.login.x, color: 'danger', display: 'bottom', duration: 3000});
        } else if (type === 'username' && !username) {
            toast({message: locale.login.y, color: 'danger', display: 'bottom', duration: 3000});
        } else {
            const item = {
                account: account.current,
                type: type,
                reset: true
            }
            if (type === 'email') {
                item.id = email;
            } else if (type === 'mobile') {
                item.id = mobile.replace(/[()\s-]/g, '');
                item.country = country;
            } else if (type === 'username') {
                item.id = username;
            }

            const message = {
                type: 'rest',
                path: 'user-reset',
                action: 'update',
                data: item
            };
            restCall(message);
        }
    }

    const Configuration = data => {
        toast({message: locale.login.t, color: 'info', display: 'bottom', duration: 1000});

        const message = {
            type: 'rest',
            path: 'account-configuration',
            action: 'get',
            data: {
                account: data,
                reset: reset.current
            }
        };
        restCall(message);
    }

    const Token = useCallback(() => {
        toast({message: locale.login.r, color: 'info', display: 'bottom', duration: 1000});

        const message = {
            type: 'rest',
            path: 'token-user',
            action: 'get',
            data: {
                token: reset.current
            }
        };
        restCall(message);
    }, [reset, restCall, locale.login.r]);

    const UpdateUser = useCallback(data => {
        if (data.error && data.id) {
            toast({message: locale.login.l, color: 'danger', display: 'bottom', duration: 3000});
        }
        if (data.error && data.email) {
            toast({message: locale.login.ab, color: 'danger', display: 'bottom', duration: 3000});
        }
        if (data.toast) {
            toast({message: locale.login.m, color: 'info', display: 'bottom', duration: 1000});
        }
    }, [locale.login.l, locale.login.m, locale.login.ab]);

    const GetTokenUser = useCallback(data => {
        if (data.email) {
            setEmail(data.email);
        }
        if (data.mobile) {
            setMobile(data.mobile);
        }
        if (data.username) {
            setUsername(data.username);
        }
        if (data.email || data.mobile || data.username) {
            Dialog({view:'passwordReset'});
        }
        if (data.error) {
            toast({message: locale.login.j, color: 'danger', display: 'bottom', duration: 3000});
        }
    }, [Dialog, locale.login.j]);

    const GetUser = useCallback(data => {
        if (data.toast) {
            setNext(false);
        }
        if (data.error) {
            toast({message: locale.login.l, color: 'danger', display: 'bottom', duration: 3000});
        }
    }, [locale.login.l]);

    const GetToken = useCallback(data => {
        if (data.token) {
            window.localStorage.token = data.token;
            token.current = data.token;
        }
        if (data.url) {
            Navigation({view: 'home'});
        }
        if (data.error && data.id) {
            toast({message: locale.login.l, color: 'danger', display: 'bottom', duration: 3000});
        }
        if (data.error && data.password) {
            toast({message: locale.login.o, color: 'danger', display: 'bottom', duration: 3000});
        }
        if (data.error && data.reset) {
            toast({message: locale.login.j, color: 'danger', display: 'bottom', duration: 3000});
        }
        if (data.toast) {
            toast({message: locale.login.p, color: 'info', display: 'bottom', duration: 3000});
        }
        if (dialog.view) {
            Dialog({view: 'close'});
        }
    }, [locale.login.l, locale.login.o, locale.login.j, locale.login.p, Dialog, dialog.view, Navigation]);

    const GetAccountConfiguration = useCallback(data => {
        if (data.configuration) {
            let item = {...data.configuration};
            item.locale.es = Object.assign(item.locale.es, Spanish);
            item.locale.en = Object.assign(item.locale.en, English);
            setConfiguration(item);
            if (window.localStorage.locale && window.localStorage.locale === 'en') {
                setLocale(item.locale.en);
            } else if (window.localStorage.locale && window.localStorage.locale === 'es') {
                setLocale(item.locale.es);
            }
        }
        if (data.account) {
            account.current = data.account;
            window.localStorage.account = data.account;
        }
        if (data.logo) {
            logo.current = data.logo;
        }
        if (data.displayName) {
            displayName.current = data.displayName;
        }
        if (data.options) {
            setOptions(data.options);
        }
        if (data.dialog) {
            setDialog(data.dialog);
        }
        if (data.navigation && data.url) {
            data.navigation.view = 'home';
            setNavigation(data.navigation);
        } else if (data.navigation && !data.url) {
            setNavigation(data.navigation);
        }
        if (data.error) {
            alert({title: locale.login.u, okText: locale.login.k});
            window.localStorage.account = 'smartdochealth';
            account.current = 'smartdochealth';
            Configuration('smartdochealth');
        }
        if (reset.current) {
            Token();
        }
    }, [locale.login.k, Configuration, account.current, locale.login.s, locale.login.u, locale.login.r, Token, reset]);

    useEffect(() => {
        if (restResponse && !Empty(restResponse) && restResponse.action === 'get' && restResponse.path === 'user') {
            GetUser(restResponse.data);
            setRestResponse({});
        }
    }, [restResponse, GetUser, setRestResponse])

    useEffect(() => {
        if (restResponse && !Empty(restResponse) && restResponse.action === 'update' && restResponse.path === 'user') {
            UpdateUser(restResponse.data);
            setRestResponse({});
        }
    }, [restResponse, UpdateUser, setRestResponse])

    useEffect(() => {
        if (restResponse && !Empty(restResponse) && restResponse.action === 'get' && restResponse.path === 'token-user') {
            GetTokenUser(restResponse.data);
            setRestResponse({});
        }
    }, [restResponse, GetTokenUser, setRestResponse])

    useEffect(() => {
        if (restResponse && !Empty(restResponse) && restResponse.action === 'get' && restResponse.path === 'token') {
            GetToken(restResponse.data);
            setRestResponse({});
        }
    }, [restResponse, GetToken, setRestResponse])

    useEffect(() => {
            if (restResponse && !Empty(restResponse) && restResponse.action === 'get' && restResponse.path === 'account-configuration') {
                GetAccountConfiguration(restResponse.data);
                setRestResponse({});
            }
            }, [restResponse, GetAccountConfiguration, setRestResponse])

    useEffect(() => {
        Configuration(account.current);
        }, [])

    return (
        <div className="mbsc-grid cs-login-main">
            {!Empty(configuration) &&
                <div className="mbsc-row mbsc-justify-content-center">
                    <div className="mbsc-col-sm-9 mbsc-col-md-7 mbsc-col-xl-5 cs-login-card">
                        {displayName.current !== 'SmartDocFitness' ?
                            <div style={{backgroundImage: `url(${logo.current})`}} className="cs-login-card-logo" />
                            :
                            <div className="cs-login-card-logo-default" />
                        }
                        <div className={(!next && type === 'mobile') ? 'cs-login-header-mobile' : ''}>
                            {next &&
                                <h2 className="cs-login-card-title mbsc-txt-muted">
                                    {locale.login.a}
                                </h2>
                            }
                            {!next && type === 'mobile' &&
                                <h2 className="cs-login-card-title mbsc-txt-muted">
                                    {mobile}
                                </h2>
                            }
                            {!next && type === 'email' &&
                                <h2 className="cs-login-card-title mbsc-txt-muted">
                                    {email}
                                </h2>
                            }
                            {!next && type === 'username' &&
                                <h2 className="cs-login-card-title mbsc-txt-muted">
                                    {username}
                                </h2>
                            }
                        </div>
                        <SegmentedGroup theme="ios" themeVariant="light" className="cs-login-nav" name="login" color="primary" value={type} onChange={Type}>
                            <Segmented value="email" theme="ios" themeVariant="light" >
                                {locale.login.b}
                            </Segmented>
                            <Segmented value="mobile" theme="ios" themeVariant="light" >
                                {locale.login.v}
                            </Segmented>
                            <Segmented value="username" theme="ios" themeVariant="light" >
                                {locale.login.w}
                            </Segmented>
                        </SegmentedGroup>
                        {next && type === 'mobile' &&
                            <div className="mbsc-row">
                                <div className="mbsc-col-4 cs-login-inputs-left">
                                    <Select
                                        theme="ios"
                                        themeVariant="light"
                                        animation="slide-down"
                                        dropdown={false}
                                        inputStyle="outline"
                                        label={locale.login.aa}
                                        labelStyle="floating"
                                        rows={locale.login.z.length}
                                        display="anchored"
                                        touchUi={false}
                                        value={country}
                                        data={locale.login.z}
                                        disabled={mobile.length !== 0}
                                        onChange={(event) => Code(event.value)}
                                    />
                                </div>
                                <div className="mbsc-col-8 cs-login-inputs-right">
                                    <Input theme="ios" themeVariant="light" className="cs-login-inputs-right-input" label={locale.login.v} inputStyle="outline" labelStyle="floating" type="text" name="mobile" value={mobile} onChange={ev => Mobile(ev.target.value)} />
                                </div>
                            </div>
                        }
                        {next && type === 'email' &&
                            <Input theme="ios" themeVariant="light" inputStyle="outline" label={locale.login.b} labelStyle="floating" type="email" name="Email" value={email} onChange={ev => setEmail(ev.target.value)} />
                        }
                        {next && type === 'username' &&
                            <Input theme="ios" themeVariant="light" inputStyle="outline" label={locale.login.w} labelStyle="floating" type="text" name="Username" value={username} onChange={ev => setUsername(ev.target.value)} />
                        }
                        {!next &&
                            <Input theme="ios" themeVariant="light" inputStyle="outline" label={locale.login.c} labelStyle="floating" minLength="8" type="password" name="Password" passwordToggle={true} value={password} onChange={ev => setPassword(ev.target.value)} />
                        }
                        {next &&
                            <div className="mbsc-row mbsc-justify-content-end">
                                <div className="mbsc-col-4 mbsc-button-group-block">
                                    <Button color="primary" variant="flat" theme="ios" themeVariant="light" className="cs-login-button mbsc-bold" onClick={() => Next()} >
                                        {locale.login.e}
                                    </Button>
                                </div>
                            </div>
                        }
                        {!next &&
                            <div className="mbsc-row mbsc-justify-content-end">
                                <div className="mbsc-col-4 mbsc-button-group-block">
                                    <Button color="primary" variant="flat" theme="ios" themeVariant="light" className="cs-login-button mbsc-bold" onClick={() => Auth()} >
                                        {locale.login.a}
                                    </Button>
                                </div>
                            </div>
                        }
                        <div className="mbsc-row cs-login-footer-section">
                            <div className="mbsc-col cs-login-footer">
                                <Button theme="ios" themeVariant="light" className="cs-login-button-lang mbsc-bold" onClick={() => Navigation({view: 'register'})} variant="flat" >
                                    <FontAwesomeIcon className="cs-login-icon-secondary" icon={faWatchFitness} /> {locale.login.d}
                                </Button>
                            </div>
                            <div className="mbsc-col cs-login-footer mbsc-align-right">
                                <Button theme="ios" themeVariant="light" className="cs-login-button-lang mbsc-bold" onClick={() => Forgot()} variant="flat" >
                                    {locale.login.f}
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            }
            {dialog.view === 'loginReset' &&
                <LoginReset
                    dialog={dialog}
                    Dialog={Dialog}
                    setEmail={setEmail}
                    locale={locale}
                    email={email}
                    mobile={mobile}
                    setMobile={setMobile}
                    username={username}
                    setUsername={setUsername}
                    type={type}
                    reset={reset}
                    account={account.current}
                />
            }
        </div>
    );
}

export default Login;
