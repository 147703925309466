import React, {useContext} from 'react';
import {SegmentedGroup, Segmented} from "@mobiscroll/react";
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import '../styles/dataMetricActivity.css';
import {NetworkContext} from "../utils/NetworkContext";

function DataMetricActivity({locale, navigation, Navigation, date, setToast}) {
  const {restCall} = useContext(NetworkContext);

  const Activity = data => {
    setToast({message: locale.dataMetricActivity.d, color: 'info', display: 'bottom', duration: 1000});
    Navigation({item: data.target.value});

    const message = {
      type: 'rest',
      path: 'activity',
      action: 'get',
      data: {
        date: date,
        range: navigation.tab,
        metric: data.target.value,
        block: 'dataMetricActivity'
      }
    };
    restCall(message);
  }

  return (
      <div className="mbsc-row cs-data-metric-activity-main">
        <div className="mbsc-col cs-data-metric-activity-col">
          <SegmentedGroup theme="ios" themeVariant="light" className="cs-data-metric-activity-nav" name="dataMetricActivity" color="primary" value={navigation.item} onChange={Activity}>
            <Segmented value="steps" theme="ios" themeVariant="light">
              {locale.dataMetricActivity.a}
            </Segmented>
            <Segmented value="distance" theme="ios" themeVariant="light" >
              {locale.dataMetricActivity.b}
            </Segmented>
            <Segmented value="calories" theme="ios" themeVariant="light" >
              {locale.dataMetricActivity.c}
            </Segmented>
          </SegmentedGroup>
        </div>
      </div>
  );
}

export default DataMetricActivity;
