import React, {useContext} from 'react';
import {SegmentedGroup, Segmented} from "@mobiscroll/react";
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import '../styles/dataMetricHr.css';
import {NetworkContext} from "../utils/NetworkContext";

function DataMetricHr({locale, navigation, Navigation, date, setToast}) {
  const {restCall} = useContext(NetworkContext);

  const HR = data => {
    setToast({message: locale.dataMetricHr.c, color: 'info', display: 'bottom', duration: 1000});
    Navigation({item: data.target.value});

    const message = {
      type: 'rest',
      path: 'hr',
      action: 'get',
      data: {
        date: date,
        metric: data.target.value,
        block: 'dataMetricHr'
      }
    };
    restCall(message);
  }

  return (
      <div className="mbsc-row cs-data-metric-hr-main">
        <div className="mbsc-col cs-data-metric-hr-col">
          <SegmentedGroup theme="ios" themeVariant="light" className="cs-data-metric-hr-nav" name="dataMetricHr" color="primary" value={navigation.item} onChange={HR}>
            <Segmented value="hr" theme="ios" themeVariant="light">
              {locale.dataMetricHr.a}
            </Segmented>
            <Segmented value="pressure" theme="ios" themeVariant="light" >
              {locale.dataMetricHr.b}
            </Segmented>
          </SegmentedGroup>
        </div>
      </div>
  );
}

export default DataMetricHr;
