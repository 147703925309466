import React, {useState, useContext} from 'react';
import {Checkbox} from "@mobiscroll/react";
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import '../styles/profilePreferences.css';
import {NetworkContext} from "../utils/NetworkContext";

function ProfilePreferences({locale, user, setToast}) {
    const {restCall} = useContext(NetworkContext);
    const [sms, setSms] = useState(user.fitnessCommunications.sms);
    const [voice, setVoice] = useState(user.fitnessCommunications.voice);
    const [email, setEmail] = useState(user.fitnessCommunications.email);

    const Save = data => {
        setToast({message: locale.profilePreferences.k, color: 'info', display: 'bottom', duration: 1000});
        if (data.sms === true || data.sms === false) {
            setSms(data.sms);
        } else if (data.voice === true || data.voice === false) {
            setVoice(data.voice);
        } else if (data.email === true || data.email === false) {
            setEmail(data.email);
        }

        const message = {
            type: 'rest',
            path: 'user',
            action: 'update',
            data: {
                email: user.email,
                fitnessCommunications: {
                    sms: (data.sms === true || data.sms === false) ? data.sms : sms,
                    voice: (data.voice === true || data.voice === false) ? data.voice : voice,
                    email: (data.email === true || data.email === false) ? data.email : email
                }
            }
        };
        restCall(message);
    }

  return (
      <div className="mbsc-row cs-profile-preferences-main">
          <div className="mbsc-col">
              <div className="cs-profile-preferences-helper">
                  <p>{locale.profilePreferences.e}</p>
              </div>
              <div className="cs-profile-preferences-helper">
                  <p>{locale.profilePreferences.f}</p>
              </div>
              <div className="cs-profile-preferences-helper">
                  <p>{locale.profilePreferences.g}</p>
              </div>
              <Checkbox theme="ios" themeVariant="light" label={locale.profilePreferences.h} checked={sms} onChange={(ev) => Save({sms: ev.target.checked})} />
              <Checkbox theme="ios" themeVariant="light" label={locale.profilePreferences.i} checked={voice} onChange={(ev) => Save({voice: ev.target.checked})} />
              <Checkbox theme="ios" themeVariant="light" label={locale.profilePreferences.j} checked={email} onChange={(ev) => Save({email: ev.target.checked})} />
          </div>
      </div>
  );
}

export default ProfilePreferences;
