import React from 'react';
import {Button} from "@mobiscroll/react";
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import '../styles/homeFooter.css';
import {faChartSimple, faWatchFitness, faUserVneck} from "@fortawesome/pro-duotone-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Empty from '../utils/Empty';

function HomeFooter({navigation, Navigation, toast}) {

    return (
        <div className="mbsc-grid cs-home-footer-toolbar">
            <div className="mbsc-row cs-home-footer-main mbsc-justify-content-around">
                <Button disabled={navigation.page === 'data'} color={(navigation.page === 'data' || !Empty(toast)) ? 'secondary' : 'primary'} variant="flat" theme="ios" themeVariant="light" className="mbsc-bold" onClick={() => Navigation({page: 'data', tab: 'day', metric: 'activity', item: 'steps'})} >
                    <FontAwesomeIcon className={(navigation.page === 'data' || !Empty(toast)) ? 'cs-home-footer-icon-selected mbsc-bold' : 'cs-home-footer-icon mbsc-bold'} icon={faChartSimple} />
                </Button>
                <Button disabled={navigation.page === 'device'} color={(navigation.page === 'device' || !Empty(toast)) ? 'secondary' : 'primary'} variant="flat" theme="ios" themeVariant="light" className="mbsc-bold" onClick={() => Navigation({page: 'device'})} >
                    <FontAwesomeIcon className={(navigation.page === 'device' || !Empty(toast)) ? 'cs-home-footer-icon-selected mbsc-bold' : 'cs-home-footer-icon mbsc-bold'} icon={faWatchFitness} />
                </Button>
                <Button disabled={navigation.page === 'profile'} color={(navigation.page === 'profile' || !Empty(toast)) ? 'secondary' : 'primary'} variant="flat" theme="ios" themeVariant="light" className="mbsc-bold" onClick={() => Navigation({page: 'profile', tab: 'account'})} >
                    <FontAwesomeIcon className={(navigation.page === 'profile' || !Empty(toast)) ? 'cs-home-footer-icon-selected mbsc-bold' : 'cs-home-footer-icon mbsc-bold'} icon={faUserVneck} />
                </Button>
            </div>
        </div>
    );
}

export default HomeFooter;
