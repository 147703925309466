import React, {useCallback, useContext, useEffect, useState} from 'react';
import {SegmentedGroup, Segmented, Button} from "@mobiscroll/react";
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import '../styles/dataChallenges.css';
import {NetworkContext} from "../utils/NetworkContext";
import dayjs from "dayjs";
import DataChallengeNew from "../dialogs/DataChallengeNew";
import Empty from "../utils/Empty";

function DataChallenges({locale, navigation, Navigation, setDate, setToast, Dialog, dialog, width, setChartData, setChallenge}) {
  const {restCall, restResponse, setRestResponse} = useContext(NetworkContext);
  const [challenges, setChallenges] = useState([]);
  const [loading, setLoading] = useState(true);

  const Challenge = useCallback(data => {
    if (data.target?.value === '_join_challenge_') {
      Dialog({view: 'homeDataChallengeNew'});
    } else {
      const itemDate = dayjs().format('YYYY-M-D');
      setDate(itemDate);
      setToast({message: locale.dataChallenges.a, color: 'info', display: 'bottom', duration: 1000});
      Navigation({item: data.target?.value ? data.target.value : data, tab: 'day'});

      const message = {
        type: 'rest',
        path: 'challenge',
        action: 'get',
        data: {
          date: itemDate,
          range: navigation.tab,
          challenge: data.target?.value ? data.target.value : data
        }
      };
      restCall(message);
    }
  }, [restCall, Dialog, Navigation, locale.dataChallenges.a, navigation.tab]);

  const Challenges = useCallback(() => {
    setToast({message: locale.dataChallenges.a, color: 'info', display: 'bottom', duration: 1000});

    const message = {
      type: 'rest',
      path: 'challenges',
      action: 'get',
      data: {}
    };
    restCall(message);
  }, [restCall, locale.dataChallenges.a]);

  const GetChallenge = useCallback(data => {
    if (data.chartData) {
      setChartData(data.chartData);
    }
    if (data.challenge) {
      setChallenge(data.challenge);
    }
  }, []);

  const GetChallenges = useCallback(data => {
    if (data.challenges && data.challenges.length > 0) {
      setChallenges(data.challenges);
      Challenge(data.challenges[0].code);
    }
    if (loading) {
      setLoading(false);
    }
  }, [Challenge, loading]);

  const DeleteChallengeUser = useCallback(data => {
    if (data.toast) {
      setChallenge({});
      setChartData({});
      setChallenges([]);
      Challenges();
    }
  }, [Challenges]);

  const PutChallengeUser = useCallback(data => {
    if (data.toast) {
      setChallenge({});
      setChartData({});
      setChallenges([]);
      Challenges();
    } else if (data.error && data.challenge) {
      setToast({message: locale.dataChallenges.c, color: 'danger', display: 'bottom', duration: 3000});
    } else if (data.error && data.user) {
      setToast({message: locale.dataChallenges.d, color: 'danger', display: 'bottom', duration: 3000});
    }
    Dialog({view: 'close'});
  }, [Challenges, Dialog, locale.dataChallenges.c, locale.dataChallenges.d]);

  useEffect(() => {
    if (!Empty(restResponse) && restResponse.action === 'put' && restResponse.path === 'challenge-user') {
      PutChallengeUser(restResponse.data);
      setRestResponse({});
    }
  }, [restResponse, PutChallengeUser, setRestResponse]);

  useEffect(() => {
    if (!Empty(restResponse) && restResponse.action === 'delete' && restResponse.path === 'challenge-user') {
      DeleteChallengeUser(restResponse.data);
      setRestResponse({});
    }
  }, [restResponse, DeleteChallengeUser, setRestResponse]);

  useEffect(() => {
    if (!Empty(restResponse) && restResponse.action === 'get' && restResponse.path === 'challenges') {
      GetChallenges(restResponse.data);
      setRestResponse({});
    }
  }, [restResponse, GetChallenges, setRestResponse]);

  useEffect(() => {
    if (!Empty(restResponse) && restResponse.action === 'get' && restResponse.path === 'challenge') {
      GetChallenge(restResponse.data);
      setRestResponse({});
    }
  }, [restResponse, GetChallenge, setRestResponse]);

  useEffect(() => {
    Challenges();
  }, []);

  return (
      <div className={challenges.length !== 0 ? "mbsc-row cs-data-challenges-main" : "mbsc-row cs-data-challenges-main mbsc-justify-content-center"}>
        {!loading && challenges.length === 0 &&
            <Button color="primary" variant="flat" theme="ios" themeVariant="light" className="cs-data-challenges-button mbsc-bold" onClick={() => Dialog({view: 'homeDataChallengeNew'})}>
              {locale.dataChallenges.b}
            </Button>
        }
        {!loading && challenges.length !== 0 &&
          <div className="mbsc-col cs-data-challenges-col">
                <SegmentedGroup theme="ios" themeVariant="light" className="cs-data-challenges-nav" name="dataChallenges" color="primary" value={navigation.item} onChange={Challenge}>
                  {challenges.map((item) => {
                    return (
                        <Segmented key={item.code} value={item.code} theme="ios" themeVariant="light" >
                          {item.code}
                        </Segmented>
                    );
                  })}
                  <Segmented value="_join_challenge_" theme="ios" themeVariant="dark" >
                    {locale.dataChallenges.b}
                  </Segmented>
                </SegmentedGroup>
          </div>
        }
        {dialog.view === 'dataChallengeNew' &&
            <DataChallengeNew
                dialog={dialog}
                Dialog={Dialog}
                locale={locale}
                width={width}
            />
        }
      </div>
  );
}

export default DataChallenges;
