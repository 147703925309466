import React from 'react';
import {Button} from "@mobiscroll/react";
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import './styles/header.css';
import {faLanguage} from "@fortawesome/pro-duotone-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import HeaderLocale from './dialogs/HeaderLocale';
import HeaderAccount from './dialogs/HeaderAccount';

function Header({locale, displayName, dialog, Dialog, configuration, setLocale, width, Navigation, navigation}) {

    return (
        <div className="mbsc-grid cs-header-toolbar">
            <div className="mbsc-row">
                <div className={width > 768 ? 'mbsc-col' : 'mbsc-col cs-header-col-xs'}>
                    <div className="mbsc-row">
                        {displayName === 'SmartDocFitness' ?
                            <div className="cs-header-logo-black" onClick={() => navigation.view === 'register' ? Navigation({view:'login'}) : Dialog({view: 'headerAccount'})} />
                            :
                            <div className="cs-header-logo-water" onClick={() => navigation.view === 'register' ? Navigation({view:'login'}) : Dialog({view: 'headerAccount'})} />
                        }
                        <Button color="primary" variant="flat" theme="ios" themeVariant="light" className="cs-header-display mbsc-bold" onClick={() => navigation.view === 'register' ? Navigation({view:'login'}) : Dialog({view: 'headerAccount'})} >
                            {displayName}
                        </Button>
                    </div>
                </div>
                <div className={width > 768 ? 'mbsc-col-auto' : 'mbsc-col-auto cs-header-col-xs'}>
                    <div className="mbsc-row mbsc-justify-content-end">
                        <Button color="primary" variant="flat" theme="ios" themeVariant="light" id="headerLocale" className="cs-dashboard-header-button-menu mbsc-bold" title={locale.header.a} onClick={() => Dialog({view: 'headerLocale'})} >
                            <FontAwesomeIcon className="cs-header-navigation-active" icon={faLanguage} />
                        </Button>
                    </div>
                </div>
            </div>
            {dialog.view === 'headerLocale' &&
                <HeaderLocale
                    dialog={dialog}
                    configuration={configuration}
                    Dialog={Dialog}
                    user={{}}
                    setUser={null}
                    setLocale={setLocale}
                    locale={locale}
                />
            }
            {dialog.view === 'headerAccount' &&
                <HeaderAccount
                    locale={locale}
                    dialog={dialog}
                    Dialog={Dialog}
                    width={width}
                />
            }
        </div>
    );
}

export default Header;
