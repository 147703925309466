import React, {useEffect, useState, useCallback, useContext, useRef} from 'react';
import {Button, Checkbox, Select, confirm, toast as mobiToast} from "@mobiscroll/react";
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import dayjs from 'dayjs';
import '../styles/device.css';
import Empty from '../utils/Empty';
import {NetworkContext} from "../utils/NetworkContext";
import device from "../images/deviceM5.png";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBatteryBolt, faBatteryEmpty, faBatteryFull, faBatteryHalf, faBatteryQuarter, faBatteryThreeQuarters, faRotate, faSignalStream, faSignalStreamSlash, faTrashCan, faWaveformLines} from "@fortawesome/pro-duotone-svg-icons";

const State = data => {
    return !!(data.device && data.device.gatt && data.device.gatt.connected);
}

function Device({locale, configuration, devices, setDevices, session, toast, setToast, refresh, width}) {
    const {restCall, restResponse, setRestResponse} = useContext(NetworkContext);
    const bytes = useRef([]);
    const ble = useRef('');
    const [receive, setReceive] = useState([]);

    const Disabled = () => {
        return !Empty(toast);
    }

    const Update = data => {
        if (!ble.current) {
            setToast({message: locale.device.ae, duration: false, color: 'info'});
            let array = devices;
            let state = {};

            array = array.map(function (item) {
                if (item.data.name === data.device) {
                    if (data.language) {
                        item.data.state.language = data.language;
                    }
                    if (data.unit) {
                        item.data.state.unit = data.unit;
                    }
                    if (data.timeFormat) {
                        item.data.state.timeFormat = data.timeFormat;
                    }
                    if (data.upHander === true || data.upHander === false) {
                        item.data.state.upHander = data.upHander;
                    }
                    if (data.notify === true || data.notify === false) {
                        item.data.state.notify = data.notify;
                    }
                    if (data.heart === true || data.heart === false) {
                        item.data.heart = data.heart;
                    }
                    state = item.data.state;
                }
                return item;
            });
            setDevices(array);

            if (data.heart === true || data.heart === false) {
                BLE({command: 'heart', device: data.device, heart: data.heart});
            } else {
                BLE({command: 'state', device: data.device, state: state});
            }
        }
    }

    const Delete = data => {
        confirm({
            title: locale.device.t,
            message: locale.device.af,
            okText: locale.device.t,
            cancelText: locale.device.ag,
            callback: (res) => {
                if (res) {
                    const message = {
                        type: 'rest',
                        path: 'device-challenge',
                        action: 'update',
                        data: {
                            name: data
                        }
                    };
                    restCall(message);
                }
            }
        });
    }

    const Devices = () => {
        setToast({message: locale.device.ah, color: 'info', display: 'bottom', duration: 1000});

        const message = {
            type: 'rest',
            path: 'devices',
            action: 'get',
            data: {}
        };
        restCall(message);
    }

    const Receive = data => {
        const itemBuffer = new Uint8Array(data.target.value.buffer);
        const itemBytes = Array.from(itemBuffer);

        if (bytes.current.length === 0 && configuration.bytes.first.includes(itemBytes[0]) && configuration.bytes.second.includes(itemBytes[1])) {
            bytes.current = itemBytes;

        } else if (bytes.current.length === 0 && !(configuration.bytes.first.includes(itemBytes[0]) && configuration.bytes.second.includes(itemBytes[1]))) {
            setReceive(itemBytes);

        } else if (bytes.current.length !== 0 && itemBytes.length === configuration.bytes.size) {
            bytes.current = bytes.current.concat(itemBytes);

        } else if (bytes.current.length !== 0 && itemBytes.length !== configuration.bytes.size) {
            bytes.current = bytes.current.concat(itemBytes);
            setReceive(bytes.current);
            bytes.current = [];
        }
    }

    const Connect = data => {
        const item = {...data};
        const array = devices;
        setToast({message: locale.device.ab, duration: false, color: 'info', display: 'bottom'});

        navigator.bluetooth.requestDevice({filters: [{name: item.data.name}], optionalServices: [configuration.ble.service]}).then(itemDevice => {
            item.data.id = itemDevice.id;
            item.device = itemDevice;

            return item.device.gatt.connect();
        }).then(itemServer => {
            item.server = itemServer

            return itemServer.getPrimaryService(configuration.ble.service);
        }).then(itemService => {
            item.service = itemService;

            return Promise.all([
                itemService.getCharacteristic(configuration.ble.read).then(SetReadCharacteristic),
                itemService.getCharacteristic(configuration.ble.write).then(SetWriteCharacteristic),
            ]);
        }).catch(error => {
            mobiToast({message: locale.device.ac, color: 'danger', display: 'bottom', duration: 3000});
        });

        const SetReadCharacteristic = itemCharacteristic => {
            item.characteristic_notify = itemCharacteristic;
            item.characteristic_notify.startNotifications();
            item.characteristic_notify.addEventListener('characteristicvaluechanged', Receive);
        }

        const SetWriteCharacteristic = itemCharacteristic => {
            item.characteristic = itemCharacteristic;

            array.map(itemBand => {
                if (itemBand.data.name === item.data.name) {
                    itemBand.data.id = item.data.id;
                    itemBand.device = item.device;
                    itemBand.server = item.server;
                    itemBand.service = item.service;
                    itemBand.characteristic_notify = item.characteristic_notify;
                    itemBand.characteristic = item.characteristic;
                }
                return itemBand;
            });

            setDevices(array);
            BLE({command: 'connect', device: item.data.name});
        }
    }

    const Sync = data => {
        setToast({message: locale.device.v, duration: false, color: 'info', display: 'bottom'});
        BLE({command: 'sync', device: data});
    }

    const Find = data => {
        setToast({message: locale.device.ai, duration: false, color: 'info', display: 'bottom'});
        BLE({command: 'find', device: data});
    }

    const BLE = useCallback(data => {

        let message = {};
        message.type = 'rest';
        message.path = 'ble';
        message.action = 'get';
        message.data = {};
        if (data.ble) {
            message.data.ble = data.ble;
        }
        if (data.device) {
            message.data.device =  data.device;
        }
        if (data.continue === true || data.continue === false) {
            message.data.continue =  data.continue;
        }
        if (data.command) {
            message.data.command =  data.command;
        }
        if (data.command && data.command === 'connect') {
            message.data.date = {};
            message.data.date.year = dayjs().format('YYYY');
            message.data.date.month = dayjs().format('M');
            message.data.date.day = dayjs().format('D');
            message.data.date.hour = dayjs().format('H');
            message.data.date.minute = dayjs().format('m');
            message.data.date.seconds = dayjs().format('s');
        }
        if (data.command && data.command === 'sync') {
            message.data.date =  dayjs().format('YYYY-M-D');
            message.data.tz =  dayjs().format('Z');
        }
        if (data.command && data.command === 'state') {
            message.data.state =  data.state;
        }
        if (data.command && data.command === 'heart') {
            message.data.heart =  data.heart;
        }
        restCall(message);
    }, [restCall]);

    const UpdateDeviceChallenge = useCallback(data => {
        if (data.toast) {
            setToast({message: locale.device.w, color: 'info', display: 'bottom', duration: 1000});
        }
        if (data.name && devices.length === 1) {
            setDevices([]);
        }
        if (data.name && devices.length > 1) {
            let array = devices;
            const item = devices.length;

            array = array.filter(function(item) {
                return item.data.name !== data.name;
            });
            if (item !== array.length) {
                setDevices(array);
            }
        }
    }, [devices, locale.device.w, setDevices]);

    const GetDevices = useCallback(data => {
        if (data.devices) {
            setDevices(data.devices);
        }
    }, [setDevices]);

    const PutBytes = useCallback(data => {
        if (data.property) {
            const array = devices;

            array.map(function(item) {
                if (item.data.name === data.device) {
                    item.data = Object.assign(item.data, data.property);
                }
                return item;
            });
            setDevices(array);
        }
        if (data.ble) {
            ble.current = data.ble;
            if (data.continue === true || data.continue === false) {
                BLE({ble: data.ble, continue: data.continue});
            } else {
                BLE({ble: data.ble});
            }
        } else if (!data.ble) {
            ble.current = '';
            setToast({});
        }
    }, [BLE, devices]);

    const GetBLE = useCallback(data => {
        if (data.ble) {
            ble.current = data.ble;
        }
        if (data.bytes && data.device) {
            devices.map(item => {
                if (item.data.name === data.device) {
                    const itemWrite = new Uint8Array(data.bytes);
                    item.characteristic.writeValueWithoutResponse(itemWrite);
                }
                return item;
            });
        }
    }, [devices]);

    useEffect(() => {
        if (!Empty(restResponse) && restResponse.action === 'get' && restResponse.path === 'ble' ) {
            GetBLE(restResponse.data);
            setRestResponse({});
        }
    }, [restResponse, GetBLE, setRestResponse]);

    useEffect(() => {
        if (!Empty(restResponse) && restResponse.action === 'put' && restResponse.path === 'bytes' ) {
            PutBytes(restResponse.data);
            setRestResponse({});
        }
    }, [restResponse, PutBytes, setRestResponse]);

    useEffect(() => {
        if (!Empty(restResponse) && restResponse.action === 'get' && restResponse.path === 'devices') {
            GetDevices(restResponse.data);
            setRestResponse({});
        }
    }, [restResponse, GetDevices, setRestResponse]);

    useEffect(() => {
        if (!Empty(restResponse) && restResponse.action === 'update' && restResponse.path === 'device-challenge') {
            UpdateDeviceChallenge(restResponse.data);
            setRestResponse({});
        }
    }, [restResponse, UpdateDeviceChallenge, setRestResponse]);

    useEffect(() => {
        if (receive.length !== 0) {
            const item = receive;
            setReceive([]);

            const message = {
                type: 'rest',
                path: 'bytes',
                action: 'put',
                data: {
                    ble: ble.current,
                    bytes: item
                }
            };
            restCall(message);
        }
    }, [receive, restCall]);

    useEffect(() => {
        if (devices.length === 0) {
            Devices();
        }
    }, []);

    return (
        <div className={width > 768 ? "mbsc-grid cs-device-grid" : "mbsc-grid cs-device-grid-small"}>
            {devices.length === 0 &&
                <div className="mbsc-row cs-device-main mbsc-justify-content-center">
                    <Button color="primary" variant="flat" theme="ios" themeVariant="light" className="cs-device-button-disabled mbsc-bold" >
                        {locale.device.y}
                    </Button>
                </div>
            }
            {!refresh && devices.length !== 0 && devices.map((item) => {
                return (
                    <div key={item.data.name} className={devices.length !== 0 ? "mbsc-row cs-device-main-multiple" : "mbsc-row cs-device-main"}>
                        <div style={{backgroundImage: "url(" + device + ")"}} className="cs-device-avatar" />
                        <div className="mbsc-col">
                            <div className="cs-device-device-name">
                                <h3>
                                    {item.data.name}
                                </h3>
                            </div>
                            <div className="mbsc-row cs-device-device-battery mbsc-txt-muted">
                                {(item.data.battery === -1 || item.data.battery > 100) &&
                                    <FontAwesomeIcon icon={faBatteryBolt} className="cs-device-device-battery-icon" />
                                }
                                {item.data.battery === 100 &&
                                    <FontAwesomeIcon icon={faBatteryFull} className="cs-device-device-battery-icon" />
                                }
                                {item.data.battery < 100 && item.data.battery >= 75 &&
                                    <FontAwesomeIcon icon={faBatteryThreeQuarters} className="cs-device-device-battery-icon" />
                                }
                                {item.data.battery < 75 && item.data.battery >= 50 &&
                                    <FontAwesomeIcon icon={faBatteryHalf} className="cs-device-device-battery-icon" />
                                }
                                {item.data.battery < 50 && item.data.battery >= 25 &&
                                    <FontAwesomeIcon icon={faBatteryQuarter} className="cs-device-device-battery-icon" />
                                }
                                {item.data.battery < 25 && item.data.battery >= 0 &&
                                    <FontAwesomeIcon icon={faBatteryEmpty} className="cs-device-device-battery-icon" />
                                }
                                {item.data.battery >= 0 && item.data.battery <= 100 &&
                                    <div className="cs-device-detail-battery">{item.data.battery} %</div>
                                }
                                {(item.data.battery === -1 || item.data.battery > 100) &&
                                    <div className="cs-device-detail-battery">{locale.device.x}</div>
                                }
                                {item.data && item.data.current &&
                                    <div>
                                        |&nbsp;&nbsp;{item.data.current.steps} {locale.device.a}&nbsp;&nbsp;|&nbsp;&nbsp;{item.data.current.kcalories} {locale.device.b}&nbsp;&nbsp;|&nbsp;&nbsp;{item.data.current.meters} {locale.device.c}
                                    </div>
                                }
                            </div>
                            {State(item) && item.data.state && !Empty(item.data.state) &&
                                <div className="cs-device-device-details">
                                    <Checkbox disabled={Disabled()} theme="ios" themeVariant="light" label={locale.device.d} checked={item.data.heart} onChange={(ev) => Update({device: item.data.name, heart: ev.target.checked})} />
                                    <Checkbox disabled={Disabled()} theme="ios" themeVariant="light" label={locale.device.e} checked={item.data.state.upHander} onChange={(ev) => Update({device: item.data.name, upHander: ev.target.checked})} />
                                    <Checkbox disabled={Disabled()} theme="ios" themeVariant="light" label={locale.device.f} checked={item.data.state.notify} onChange={(ev) => Update({device: item.data.name, notify: ev.target.checked})} />
                                    <Select
                                        theme="ios"
                                        themeVariant="light"
                                        animation="slide-down"
                                        inputStyle="outline"
                                        label={locale.device.g}
                                        labelStyle="floating"
                                        touchUi={false}
                                        rows={locale.device.aj.length}
                                        display="anchored"
                                        data={locale.device.aj}
                                        value={item.data.state.language}
                                        onChange={(event) => Update({device: item.data.name, language: event.value})}
                                    />
                                    <Select
                                        theme="ios"
                                        themeVariant="light"
                                        animation="slide-down"
                                        inputStyle="outline"
                                        label={locale.device.m}
                                        labelStyle="floating"
                                        touchUi={false}
                                        rows={locale.device.ak.length}
                                        display="anchored"
                                        data={locale.device.ak}
                                        value={item.data.state.unit}
                                        onChange={(event) => Update({device: item.data.name, unit: event.value})}
                                    />
                                    <Select
                                        theme="ios"
                                        themeVariant="light"
                                        animation="slide-down"
                                        inputStyle="outline"
                                        label={locale.device.p}
                                        labelStyle="floating"
                                        touchUi={false}
                                        rows={locale.device.al.length}
                                        display="anchored"
                                        data={locale.device.al}
                                        value={item.data.state.timeFormat}
                                        onChange={(event) => Update({device: item.data.name, timeFormat: event.value})}
                                    />
                                </div>
                            }
                            <div className="mbsc-row cs-device-footer">
                                <div className="mbsc-col">
                                    <div className="mbsc-row">
                                        {!State(item) && session.navigator.chrome &&
                                            <Button disabled={Disabled()} color="primary" variant="flat" theme="ios" themeVariant="light" className="cs-device-button mbsc-bold" onClick={() => Connect(item)} >
                                                <FontAwesomeIcon className="cs-device-button-icon" icon={faSignalStream} /> {locale.device.u}
                                            </Button>
                                        }
                                        {!State(item) && !session.navigator.chrome &&
                                            <Button color="primary" variant="flat" theme="ios" themeVariant="light" className="cs-device-button-disabled mbsc-bold" >
                                                <FontAwesomeIcon className="cs-device-button-icon" icon={faSignalStreamSlash} /> {locale.device.aa}
                                            </Button>
                                        }
                                        {State(item) && session.navigator.chrome &&
                                            <Button disabled={Disabled()} color="primary" variant="flat" theme="ios" themeVariant="light" className="cs-device-button mbsc-bold" onClick={() => Find(item.data.name)} >
                                                <FontAwesomeIcon className="cs-device-button-icon" icon={faWaveformLines} /> {locale.device.s}
                                            </Button>
                                        }
                                        {State(item) && session.navigator.chrome &&
                                            <Button disabled={Disabled()} color="primary" variant="flat" theme="ios" themeVariant="light" className="cs-device-button mbsc-bold" onClick={() => Sync(item.data.name)} >
                                                <FontAwesomeIcon className="cs-device-button-icon" icon={faRotate} /> {locale.device.ad}
                                            </Button>
                                        }
                                    </div>
                                </div>
                                <div className="mbsc-col-auto">
                                    <div className="mbsc-row mbsc-justify-content-end">
                                        <Button disabled={Disabled()} color="primary" variant="flat" theme="ios" themeVariant="light" className="cs-device-button mbsc-bold" onClick={() => Delete(item.data.name)} >
                                            <FontAwesomeIcon className="cs-device-button-icon" icon={faTrashCan} /> {locale.device.t}
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            })}
        </div>
    );
}

export default Device;
