import React, {useCallback, useState, useEffect, useContext} from 'react';
import {Button, Input, Segmented, SegmentedGroup, Select, Datepicker, toast} from "@mobiscroll/react";
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import './styles/register.css';
import dayjs from "dayjs";
import Empty from './utils/Empty';
import {NetworkContext} from "./utils/NetworkContext";

const LoginType = () => {
    const item = window.localStorage.login;
    if (!item) {
        window.localStorage.login = 'email'
        return 'email';
    } else {
        return item;
    }
}

function Register({locale, Navigation, account, width}) {
    const {restCall, restResponse, setRestResponse} = useContext(NetworkContext);
    const [type, setType] = useState(LoginType());
    const [country, setCountry] = useState('us');
    const [id, setId] = useState(false);
    const [email, setEmail] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [mobile, setMobile] = useState('');
    const [password, setPassword] = useState('');
    const [confirm, setConfirm] = useState('');
    const [gender, setGender] = useState('male');
    const [height, setHeight] = useState('');
    const [dob, setDob] = useState('');
    const [tos, setTos] = useState(false);
    const [language, setLanguage] = useState(window.localStorage.locale);

    function Submit() {
        if (gender === 'n/a') {
            setGender('');
        }

        const item = {
            account: account.current,
            locale: language,
            firstName: firstName,
            lastName: lastName,
            password: password,
            gender: gender,
            height: height,
            dob: dob,
            tos: tos,
            type: type
        }
        if (mobile) {
            item.mobile = mobile.replace(/[()\s-]/g, '');
            item.country = country;
        }
        if (email) {
            item.email = email;
        }

        const message = {
            type: 'rest',
            path: 'user',
            action: 'put',
            data: item
        };
        restCall(message);
    }

    const Type = data => {
        setType(data.target.value);
        window.localStorage.login = data.target.value;
    }

    const Code = data => {
        window.localStorage.country = data;
        setCountry(data);
    }

    function Next() {
        if (type === 'email' && !email) {
            toast({ message: locale.register.bc, color: 'danger', display: 'bottom', duration: 3000});
        } else if (type === 'mobile' && !mobile) {
            toast({ message: locale.register.bd, color: 'danger', display: 'bottom', duration: 3000});
        } else if (!firstName && !lastName) {
            toast({ message: locale.register.r, color: 'danger', display: 'bottom', duration: 3000});
        } else if (!password || (password && (password !== confirm))) {
            toast({ message: locale.register.h, color: 'danger', display: 'bottom', duration: 3000});
        } else {
            setId(true);
        }
    }

    function Mobile(data) {
        let formattedText = '';

        if (country === 'us') {
            const cleanedText = data.replace(/[^\d]/g, '');

            if (cleanedText.length <= 3) {
                formattedText = cleanedText;
            } else if (cleanedText.length <= 6) {
                formattedText = `(${cleanedText.slice(0, 3)}) ${cleanedText.slice(3)}`;
            } else {
                formattedText = `(${cleanedText.slice(0, 3)}) ${cleanedText.slice(3, 6)}-${cleanedText.slice(6, 10)}`;
            }
        } else if (country === 'mx') {
            const cleanedText = data.replace(/[^\d]/g, '');

            if (cleanedText.length <= 2) {
                formattedText = cleanedText;
            } else if (cleanedText.length <= 6) {
                formattedText = `(${cleanedText.slice(0, 2)}) ${cleanedText.slice(2)}`;
            } else {
                formattedText = `(${cleanedText.slice(0, 2)}) ${cleanedText.slice(2, 6)}-${cleanedText.slice(6, 10)}`;
            }
        }
        setMobile(formattedText);
    }

    function NumberHeight(data) {
        const re = /^[0-9\b]+$/;

        if (data === "" || (re.test(data) && (data <= 300))) {
            setHeight(data);
        }
    }

    const PutUser = useCallback(data => {
        if (data.error && data.user) {
            toast({message: locale.register.q, color: 'danger', display: 'bottom', duration: 3000});
        }
        if (data.toast) {
            toast({ message: locale.register.o, color: 'info', display: 'bottom', duration: 1000});
            Navigation({view: 'login'});
        }
    }, [Navigation, locale.register.q, locale.register.o]);

    useEffect(() => {
        if (!Empty(restResponse) && restResponse.action === 'put' && restResponse.path === 'user') {
            PutUser(restResponse.data);
            setRestResponse({});
        }
    }, [restResponse, PutUser, setRestResponse])

    return (
        <div className="mbsc-grid cs-register-main">
            <div className="mbsc-row mbsc-justify-content-center">
                {!tos && !id &&
                    <div className="mbsc-col-sm-12 mbsc-col-md-11 mbsc-col-xl-10 cs-register-card">
                        <div className="cs-register-title mbsc-padding">
                            <h3>
                                {locale.register.aa}
                            </h3>
                        </div>
                        <div className="mbsc-padding">
                            <p>{locale.register.ab}</p>
                        </div>
                        <div className="cs-register-form-privacy mbsc-padding">
                            <h4>{locale.register.ac}</h4>
                            <p>{locale.register.ad}</p>
                            <ul>
                                <li>{locale.register.ae}</li>
                                <li>{locale.register.af}</li>
                                <li>{locale.register.ag}</li>
                                <li>{locale.register.ah}</li>
                            </ul>
                            <h4>{locale.register.ai}</h4>
                            <p>{locale.register.aj}</p>
                            <ul>
                                <li>{locale.register.ak}</li>
                                <li>{locale.register.al}</li>
                                <li>{locale.register.am}</li>
                                <li>{locale.register.an}</li>
                            </ul>
                            <h4>{locale.register.ao}</h4>
                            <p>{locale.register.ap}</p>
                            <h4>{locale.register.aq}</h4>
                            <p>{locale.register.ar}</p>
                            <h4>{locale.register.as}</h4>
                            <p>{locale.register.at}</p>
                            <h4>{locale.register.au}</h4>
                            <p>{locale.register.av}</p>
                        </div>
                        <div className="mbsc-padding">
                            <p>{locale.register.aw}</p>
                        </div>
                        <div className="mbsc-row mbsc-justify-content-end">
                            <div className="mbsc-col-4 mbsc-button-group-block">
                                <Button color="primary" variant="flat" theme="ios" themeVariant="light" className="cs-register-button mbsc-bold" onClick={() => setTos(true)} >
                                    {locale.register.s}
                                </Button>
                            </div>
                        </div>
                    </div>
                }
                {tos && !id &&
                    <div className="mbsc-col-sm-12 mbsc-col-md-11 mbsc-col-xl-10 cs-register-card">
                        <div className="mbsc-padding cs-register-text-top">
                            <p>{locale.register.a}</p>
                        </div>
                        <SegmentedGroup theme="ios" themeVariant="light" className="cs-register-nav" name="register" color="primary" value={type} onChange={Type}>
                            <Segmented value="email" theme="ios" themeVariant="light" >
                                {locale.login.b}
                            </Segmented>
                            <Segmented value="mobile" theme="ios" themeVariant="light" >
                                {locale.login.v}
                            </Segmented>
                        </SegmentedGroup>
                        {type === 'email' &&
                            <Input theme="ios" themeVariant="light" inputStyle="outline" label={locale.register.b} labelStyle="floating" type="email" name="Email" value={email} onChange={ev => setEmail(ev.target.value)} />
                        }
                        {type === 'mobile' &&
                            <div className="mbsc-row cs-register-mobile-row">
                                <div className="mbsc-col-4 cs-register-inputs-left">
                                    <Select
                                        theme="ios"
                                        themeVariant="light"
                                        animation="slide-down"
                                        dropdown={false}
                                        inputStyle="outline"
                                        label={locale.register.az}
                                        labelStyle="floating"
                                        rows={locale.register.ay.length}
                                        display="anchored"
                                        touchUi={false}
                                        value={country}
                                        data={locale.register.ay}
                                        disabled={mobile.length !== 0}
                                        onChange={(event) => Code(event.value)}
                                    />
                                </div>
                                <div className="mbsc-col-8 cs-register-inputs-right">
                                    <Input theme="ios" themeVariant="light" className="cs-register-inputs-right-input" label={locale.register.e} inputStyle="outline" labelStyle="floating" type="text" name="mobile" value={mobile} onChange={ev => Mobile(ev.target.value)} />
                                </div>
                            </div>
                        }
                        <Input theme="ios" themeVariant="light" inputStyle="outline" label={locale.register.c} labelStyle="floating" type="text" name="First Name" value={firstName} onChange={ev => setFirstName(ev.target.value)} />
                        <Input theme="ios" themeVariant="light" inputStyle="outline" label={locale.register.d} labelStyle="floating" type="text" name="Last Name" value={lastName} onChange={ev => setLastName(ev.target.value)} />
                        <Input theme="ios" themeVariant="light" inputStyle="outline" label={locale.register.f} labelStyle="floating" type="password" name="Password" passwordToggle={true} minLength="8" value={password} onChange={ev => setPassword(ev.target.value)} />
                        <Input theme="ios" themeVariant="light" inputStyle="outline" label={locale.register.g} labelStyle="floating" type="password" name="Confirm Password" passwordToggle={true} minLength="8" value={confirm} onChange={ev => setConfirm(ev.target.value)} />
                        <div className="mbsc-row mbsc-justify-content-end">
                            <div className="mbsc-col-4 mbsc-button-group-block">
                                <Button color="primary" variant="flat" theme="ios" themeVariant="light" className="cs-register-button mbsc-bold" onClick={() => Next()} >
                                    {locale.register.ba}
                                </Button>
                            </div>
                        </div>
                    </div>
                }
                {tos && id &&
                    <div className="mbsc-col-sm-12 mbsc-col-md-11 mbsc-col-xl-10 cs-register-card">
                        <div className="mbsc-padding cs-register-text-top">
                            <p>{locale.register.bb}</p>
                        </div>
                        <Select
                            theme="ios"
                            themeVariant="light"
                            animation="slide-down"
                            inputStyle="outline"
                            label={locale.register.i}
                            labelStyle="floating"
                            touchUi={false}
                            rows={locale.register.bg.length}
                            display="anchored"
                            data={locale.register.bg}
                            value={gender}
                            onChange={(event) => setGender(event.value)}
                        />
                        <Input theme="ios" themeVariant="light" inputStyle="outline" label={locale.register.l} labelStyle="floating" type="text" name="Height" value={height} onChange={ev => NumberHeight(ev.target.value)} />
                        <Datepicker
                            theme="ios"
                            themeVariant="light"
                            controls={['date']}
                            touchUi={width > 576 ? false : true}
                            inputStyle="outline"
                            label={locale.register.t}
                            labelStyle="floating"
                            value={dob}
                            selectMultiple={false}
                            max={dayjs().format("YYYY-MM-DD")}
                            onChange={(event) => setDob(event.value)}
                        />
                        <div className="mbsc-row mbsc-justify-content-end">
                            <div className="mbsc-col-4 mbsc-button-group-block">
                                <Button color="primary" variant="flat" theme="ios" themeVariant="light" className="cs-register-button mbsc-bold" onClick={() => Submit()} >
                                    {locale.register.v}
                                </Button>
                            </div>
                        </div>
                        <div className="mbsc-padding">
                            <p>{locale.register.w}</p>
                        </div>
                    </div>
                }
            </div>
            <div className="mbsc-row mbsc-justify-content-center">
                <div>
                    <div className="cs-register-login">
                        <p>{locale.register.x}</p>
                    </div>
                    <div className="mbsc-row mbsc-justify-content-center">
                        <Button color="primary" variant="flat" theme="ios" themeVariant="light" className="cs-register-login-button mbsc-bold" onClick={() => Navigation({view:'login'})} >
                            {locale.register.y}
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Register;
