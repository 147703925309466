import React, {useCallback, useState, useContext} from 'react';
import {Popup, toast, Input} from "@mobiscroll/react";
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import '../styles/headerAccount.css';
import {NetworkContext} from "../utils/NetworkContext";

function HeaderAccount({dialog, Dialog, locale, width}) {
    const {restCall} = useContext(NetworkContext);
    const [account, setAccount] = useState('');

  const CloseDialog = useCallback(() => {
    Dialog({view: 'close'});
  }, [Dialog]);

  const Select = () => {
    if (!account) {
      toast({message: locale.headerAccount.g, color: 'danger', display: 'bottom', duration: 3000});
    } else {
        const message = {
            type: 'rest',
            path: 'account-configuration',
            action: 'get',
            data: {
                account: account
            }
        };
        restCall(message);
    }
  }

  return (
      <Popup className="cs-header-account-popup" width={600} theme="ios" themeVariant="light" display="center" headerText={locale.headerAccount.a} buttons={[{text: locale.headerAccount.f, cssClass: 'cs-header-account-popup-save', handler: function (event) {Select()}}]} isOpen={dialog.view === 'headerAccount'} onClose={CloseDialog}>
          <div className="cs-header-account-helper">
              <p>{locale.headerAccount.b}</p>
          </div>
          <div className="cs-header-account-helper">
              <p>{locale.headerAccount.c}</p>
          </div>
          <div className="cs-header-account-helper">
              <p>{locale.headerAccount.d}</p>
          </div>
          <Input theme="ios" themeVariant="light" inputStyle="underline" labelStyle="floating" type="text" name="account" label={locale.headerAccount.e} value={account} onChange={ev => setAccount(ev.target.value)} />
      </Popup>
  );
}

export default HeaderAccount;
