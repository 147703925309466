import React, {useContext, useEffect, useCallback} from 'react';
import {Button, confirm} from "@mobiscroll/react";
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faHand} from '@fortawesome/free-solid-svg-icons';
import Empty from "../utils/Empty";
import '../styles/dataChallenge.css';
import dayjs from "dayjs";
import {NetworkContext} from "../utils/NetworkContext";
import {Doughnut} from "react-chartjs-2";
import DataChallengeRange from '../blocks/DataChallengeRange';
import {faPersonToDoor} from "@fortawesome/pro-duotone-svg-icons";

function DataChallenge({locale, user, navigation, challenge, chartData, configuration, setToast, width, Navigation, setDate}) {
  const {restCall, restResponse, setRestResponse} = useContext(NetworkContext);

  const Metric = () => {
    if ((challenge.goal.metric === 'meters' || challenge.goal.metric === 'miles') && user.unit === 'metric') {
      return locale.dataChallenge.a;
    } else if ((challenge.goal.metric === 'meters' || challenge.goal.metric === 'miles') && user.unit === 'imperial') {
      return locale.dataChallenge.d;
    } else if (challenge.goal.metric === 'steps') {
      return locale.dataChallenge.b;
    } else if (challenge.goal.metric === 'calories') {
      return locale.dataChallenge.c;
    }
  }

  const HighFive = data => {
    const date = dayjs().format('YYYY-M-D');

    const message = {
      type: 'rest',
      path: 'highFive',
      action: 'put',
      data: {
        id: data,
        group: navigation.item,
        date: date
      }
    };
    restCall(message);
  }

  const Exit = data => {
    confirm({
      title: locale.dataChallenge.u,
      message: locale.dataChallenge.v,
      okText: locale.dataChallenge.w,
      cancelText: locale.dataChallenge.x,
      callback: (res) => {
        if (res) {
          const message = {
            type: 'rest',
            path: 'challenge-user',
            action: 'delete',
            data: {
              challenge: navigation.item
            }
          };
          restCall(message);
        }
      }
    });
  }

  const PostHighfive = useCallback(data => {
    if (data.toast) {
      setToast({message: locale.dataChallenge.s, color: 'info'});
    }
    if (data.error) {
      setToast({message: locale.dataChallenge.t, color: 'danger'});
    }
  }, [locale.dataChallenge.s, locale.dataChallenge.t]);

  useEffect(() => {
    if (!Empty(restResponse) && restResponse.action === 'post' && restResponse.path === 'highFive') {
      PostHighfive(restResponse.data);
      setRestResponse({});
    }
  }, [restResponse, PostHighfive, setRestResponse])

  return (
      <>
        <div className="mbsc-row cs-data-challenge-main">
          <div className="mbsc-col">
            {navigation.tab === 'day' &&
                <h3>
                  {locale.dataChallenge.e}
                </h3>
            }
            {navigation.tab === 'week' &&
                <h3>
                  {locale.dataChallenge.f}
                </h3>
            }
            {navigation.tab === 'month' &&
                <h3>
                  {locale.dataChallenge.g}
                </h3>
            }
          </div>
          <div className="mbsc-col">
            <div className="mbsc-row mbsc-justify-content-end">
              <Button color="primary" variant="flat" theme="ios" themeVariant="light" className="mbsc-bold cs-data-challenge-leave-button" onClick={() => Exit()} >
                <FontAwesomeIcon className="cs-data-challenge-leave-icon" icon={faPersonToDoor} /><div className="cs-home-header-menu-text">{locale.dataChallenge.u}</div>
              </Button>
            </div>
          </div>
        </div>
        <div className="mbsc-row cs-data-challenge-main cs-data-challenge-header mbsc-justify-content-around">
          {!Empty(chartData) &&
              <div>
                <Doughnut options={configuration.chart.challenge.options} data={chartData} />
              </div>
          }
          {navigation.tab === 'day' &&
              <div className="mbsc-align-center">
                <div>
                  <h2>
                    {challenge.total}
                  </h2>
                </div>
                {challenge.goal.metric === 'steps' &&
                    <div>
                      <h5>
                        {locale.dataChallenge.q}
                      </h5>
                    </div>
                }
                {challenge.goal.metric === 'calories' &&
                    <div>
                      <h5>
                        {locale.dataChallenge.a}
                      </h5>
                    </div>
                }
                {(challenge.goal.metric === 'meters' || challenge.goal.metric === 'miles') && user.unit === 'metric' &&
                    <div>
                      <h5>
                        {locale.dataChallenge.o}
                      </h5>
                    </div>
                }
                {(challenge.goal.metric === 'meters' || challenge.goal.metric === 'miles') && user.unit === 'imperial' &&
                    <div>
                      <h5>
                        {locale.dataChallenge.p}
                      </h5>
                    </div>
                }
              </div>
          }
          {(navigation.tab === 'week' || navigation.tab === 'month') &&
              <div className="mbsc-align-center">
                {(challenge.goal.metric === 'steps' || challenge.goal.metric === 'calories') &&
                    <div>
                      <h2>
                        {challenge.total}
                      </h2>
                    </div>
                }
                <div>
                  <h5 className="title-cont-top">
                    {locale.dataChallenge.h}
                  </h5>
                </div>
                {challenge.goal.metric === 'steps' &&
                    <div>
                      <h5 className="title-cont-bottom">
                        {locale.dataChallenge.j}
                      </h5>
                    </div>
                }
                {challenge.goal.metric === 'calories' &&
                    <div>
                      <h5 className="title-cont-bottom">
                        {locale.dataChallenge.k}
                      </h5>
                    </div>
                }
                {(challenge.goal.metric === 'meters' || challenge.goal.metric === 'miles') && user.unit === 'metric' &&
                    <div>
                      <h5>
                        {locale.dataChallenge.i}
                      </h5>
                    </div>
                }
                {(challenge.goal.metric === 'meters' || challenge.goal.metric === 'miles') && user.unit === 'imperial' &&
                    <div>
                      <h5>
                        {locale.dataChallenge.l}
                      </h5>
                    </div>
                }
              </div>
          }
          <div className="mbsc-align-center">
            <div>
              <h2>
                {challenge.goal.target}
              </h2>
            </div>
            <div>
              <h5>
                {locale.dataChallenge.m}
              </h5>
            </div>
          </div>
          <div className="mbsc-align-center">
            <div>
              <h2>
                {challenge.percentage} %
              </h2>
            </div>
            <div>
              <h5>
                {locale.dataChallenge.n}
              </h5>
            </div>
          </div>
        </div>
        <div className="mbsc-row cs-data-challenge-main">
          <div className="mbsc-col cs-data-challenge-col">
            <DataChallengeRange
                locale={locale}
                navigation={navigation}
                Navigation={Navigation}
                width={width}
                setDate={setDate}
                setToast={setToast}
            />
          </div>
        </div>
        <div className="mbsc-row cs-data-challenge-main cs-data-challenge-bottom-main">
          <div className="mbsc-col">
            {challenge.highFive.length !== 0 &&
                <div className="mbsc-txt-muted cs-data-challenge-highfives">
                  <p>Your high fives</p>
                </div>
            }
            {navigation.tab === 'day' && challenge.highFive.length !== 0 && challenge.highFive.map((item) => {
              return (
                  <Button key={item.id} color="warning" variant="outline" theme="ios" themeVariant="light" className="mbsc-bold cs-data-challenge-cursor" >
                    <div className="mbsc-row mbsc-justify-content-center">
                      {item.avatar ?
                          <div style={{backgroundImage: "url('https://www.gravatar.com/avatar/" + item.highFiveData.avatar + "?d=robohash')"}} className="cs-data-challenge-avatar-highfive" />
                          :
                          <div style={{backgroundImage: "url('https://www.gravatar.com/avatar/" + (+ new Date()) + "?d=robohash')"}} className="cs-data-challenge-avatar-highfive" />
                      }
                      <div className="cs-data-challenge-text">
                        {item.highFiveData.firstName &&
                            item.highFiveData.firstName
                        }
                        {!item.highFiveData.firstName && item.highFiveData.alias &&
                            item.highFiveData.alias
                        }
                      </div>
                    </div>
                  </Button>
              );
            })}
            {(navigation.tab === 'week' || navigation.tab === 'month') && challenge.highFive.length !== 0 &&
                <div className="mbsc-padding cs-data-challenge-highfives-total">
                  <h4>
                    {challenge.highFive.length} High fives
                  </h4>
                </div>
            }
            {challenge.leaderboard.length !== 0 &&
                <div className="mbsc-txt-muted cs-data-challenge-leaderboard">
                  <p>Leaderboard</p>
                </div>
            }
            {challenge.leaderboard.length !== 0 && challenge.leaderboard.map((item) => {
              return (
                  <div key={item.id} className="mbsc-row cs-data-challenge-leaderboard-main">
                    <div className="mbsc-col">
                      <div className="mbsc-row">
                        {item.avatar ?
                            <div style={{backgroundImage: "url('https://www.gravatar.com/avatar/" + item.avatar + "?d=robohash')"}} className="cs-data-challenge-avatar-leaderboard" />
                            :
                            <div style={{backgroundImage: "url('https://www.gravatar.com/avatar/" + (+ new Date()) + "?d=robohash')"}} className="cs-data-challenge-avatar-leaderboard" />
                        }
                        <div className="cs-data-challenge-leaderboard-title">
                          {(item.firstName || item.lastName) && !item.nickname &&
                              <h4 className="cs-data-challenge-leaderboard-text">{item.firstName + ' ' + item.lastName}</h4>
                          }
                          {item.nickname &&
                              <h4 className="cs-data-challenge-leaderboard-text">{item.nickname}</h4>
                          }
                          {!item.firstName && !item.lastName && !item.nickname &&
                              <h4 className="cs-data-challenge-leaderboard-text">{locale.dataChallenge.r}</h4>
                          }
                          <p className="cs-data-challenge-leaderboard-text">{item.total} {Metric()}</p>
                        </div>
                      </div>
                    </div>
                    <div className="mbsc-col-auto">
                      <div className="mbsc-row cs-data-challenge-leaderboard-stats mbsc-justify-content-end">
                        {challenge.goal.highFive && navigation.tab === 'day' && item.email !== user.email &&
                            <Button color="secondary" variant="flat" theme="ios" themeVariant="light" className="mbsc-bold cs-data-challenge-cursor" onClick={() => HighFive(item.id)} >
                              <FontAwesomeIcon className="cs-data-challenge-leaderboard-hand" icon={faHand} />
                            </Button>
                        }
                        {(item.percentage || item.percentage === 0) &&
                            <Button color="info" variant="flat" theme="ios" themeVariant="light" className="mbsc-bold cs-data-challenge-cursor" >
                              {item.percentage}%
                            </Button>
                        }
                        <Button color="info" variant="flat" theme="ios" themeVariant="light" className="mbsc-bold cs-data-challenge-cursor" >
                          {item.rank}
                        </Button>
                      </div>
                    </div>
                  </div>
              );
            })}
          </div>
        </div>
      </>
  );
}

export default DataChallenge;
