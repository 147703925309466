import React, {useContext} from 'react';
import {SegmentedGroup, Segmented} from "@mobiscroll/react";
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import '../styles/dataRangeActivity.css';
import {NetworkContext} from "../utils/NetworkContext";
import dayjs from "dayjs";

function DataRangeActivity({locale, navigation, Navigation, width, setDate, setToast}) {
  const {restCall} = useContext(NetworkContext);

  const Activity = data => {
    const itemDate = dayjs().format('YYYY-M-D');
    setDate(itemDate);
    setToast({message: locale.dataRangeActivity.i, color: 'info', display: 'bottom', duration: 1000});
    Navigation({tab: data.target.value});

    const message = {
      type: 'rest',
      path: 'activity',
      action: 'get',
      data: {
        date: itemDate,
        range: data.target.value,
        metric: navigation.item,
        block: 'dataRangeActivity',
        current: dayjs().format('YYYY-M-D')
      }
    };
    restCall(message);
  }

  return (
      <div className="mbsc-row cs-data-range-activity-main">
        <div className="mbsc-col cs-data-range-activity-col">
          <SegmentedGroup theme="ios" themeVariant="light" className="cs-data-range-activity-nav" name="dataRangeActivity" color="primary" value={navigation.tab} onChange={Activity}>
            <Segmented value="day" theme="ios" themeVariant="light">
              {width > 768 ? locale.dataRangeActivity.a : locale.dataRangeActivity.e}
            </Segmented>
            <Segmented value="week" theme="ios" themeVariant="light" >
              {width > 768 ? locale.dataRangeActivity.b : locale.dataRangeActivity.f}
            </Segmented>
            <Segmented value="month" theme="ios" themeVariant="light" >
              {width > 768 ? locale.dataRangeActivity.c : locale.dataRangeActivity.g}
            </Segmented>
            <Segmented value="year" theme="ios" themeVariant="light" >
              {width > 768 ? locale.dataRangeActivity.d : locale.dataRangeActivity.h}
            </Segmented>
          </SegmentedGroup>
        </div>
      </div>
  );
}

export default DataRangeActivity;
