import React, {useState , useEffect, useRef, useCallback} from 'react';
import {Page} from "@mobiscroll/react";
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import dayjs from "dayjs";
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import {NetworkProvider} from './utils/NetworkProvider';
import './styles/app.css';
import Login from './Login';
import Register from './Register';
import Header from './Header';
import Home from './Home';
import Empty from './utils/Empty';
import English from './locale/english.json';
import Spanish from './locale/spanish.json';
dayjs.extend(utc);
dayjs.extend(timezone);

const Session = () => {
  const itemMinutes = dayjs().utcOffset();
  const itemHours = (itemMinutes >= 0 ? '+' : '-') + String(Math.floor(Math.abs(itemMinutes) / 60)).padStart(2, '0') + ':' + String(Math.abs(itemMinutes) % 60).padStart(2, '0');

  return {
    navigator: {
      agent: navigator.userAgent,
      language: navigator.language,
      referrer: document.referrer,
      chrome: /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor)
    },
    id: Math.floor(Math.random() * 1000000000000),
    version: "2.0",
    app: "eyJhbGciOiJIUzI1NiJ9.U21hcnREb2NGaXRuZXNz.G8GtBlGdDza0JoxlL-nmSpUA88rVJiJCyu61unKezKE",
    timezone: dayjs.tz.guess(),
    offset: itemHours
  };
}

const Token = data => {
  const item = window.localStorage.token;
  if (!item) {
    return '';
  } else {
    return item;
  }
}

const App = () => {
  const token = useRef(Token());
  const session = useRef(Session());
  const reset = useRef('');
  const account = useRef('');
  const logo = useRef('');
  const displayName = useRef('');
  const [options, setOptions] = useState({});
  const [width, setWidth] = useState(0);
  const [user, setUser] = useState({});
  const [configuration, setConfiguration] = useState({});
  const [locale, setLocale] = useState(English);
  const [navigation, setNavigation] = useState({});
  const [dialog, setDialog] = useState({});
  const [date, setDate] = useState(dayjs().format('YYYY-M-D'));

  const Width = () => {
    const width = window.innerWidth;
    setWidth(width);
  }

  const Locale = () => {
    if (window.localStorage.locale && window.localStorage.locale === 'en') {
      setLocale(English);
    } else if (window.localStorage.locale && window.localStorage.locale === 'es') {
      setLocale(Spanish);
    } else {
      window.localStorage.locale = 'en';
      setLocale(English);
    }
  }

  const Dialog = useCallback(data => {
    let update = data;
    let current = dialog;

    let view = '';
    let tab = '';

    if (update.view && update.view === 'close') {
      view = '';
      tab = '';
    } else if (update.view || update.tab) {
      view = current.view;

      if (update.view) {
        view = update.view;
      } else if (current.view) {
        view = current.view;
      }
      if (update.tab) {
        tab = update.tab;
      } else if (current.tab) {
        tab = current.tab;
      }
    }
    let object = {
      view: view,
      tab: tab
    }
    setDialog(object);
  }, [dialog]);

  const Navigation = useCallback(data => {
    let update = data;
    let current = navigation;
    let previous = {
      view: '',
      page: '',
      tab: '',
      item: '',
      metric: ''
    };
    let view = '';
    let page = '';
    let tab = '';
    let item = '';
    let metric = '';

    if (update.view) {
      view = update.view;
      previous.view = current.view;
      if (current.tab) {
        previous.tab = current.tab;
      }
      if (current.page) {
        previous.page = current.page;
      }
      if (current.item) {
        previous.item = current.item;
      }
      if (current.metric) {
        previous.metric = current.metric;
      }
      if (update.page) {
        page = update.page;
      } else if (update.view === current.previous?.view && current.previous?.page){
        page = current.previous.page;
      }
      if (update.tab) {
        tab = update.tab;
      } else if (update.view === current.previous?.view && current.previous?.tab){
        tab = current.previous.tab;
      }
      if (update.item) {
        item = update.item;
      } else if (update.view === current.previous?.view && current.previous?.item){
        item = current.previous.item;
      }
      if (update.metric) {
        metric = update.metric;
      } else if (update.view === current.previous?.view && current.previous?.metric){
        metric = current.previous.metric;
      }
    } else if (update.page || update.tab || update.item || update.metric) {
      view = current.view;

      if (update.page) {
        page = update.page;
      } else if (current.page) {
        page = current.page;
      }
      if (update.tab) {
        tab = update.tab;
      } else if (current.tab) {
        tab = current.tab;
      }
      if (update.item) {
        item = update.item;
      } else if (current.item) {
        item = current.item;
      }
      if (update.metric) {
        metric = update.metric;
      } else if (current.metric) {
        metric = current.metric;
      }

      previous = current.previous;
    }
    let object = {
      view: view,
      page: page,
      tab: tab,
      item: item,
      metric: metric,
      previous: previous
    }
    setNavigation(object);
  }, [navigation]);

  useEffect(() => {
        const location = window.location.href;
        const url = new URL(location);

        if (window.localStorage.account) {
          account.current = window.localStorage.account;
        } else {
          window.localStorage.account = 'smartdochealth';
          account.current = 'smartdochealth';
        }

        const itemReset = url.searchParams.get('reset');
        if (itemReset) {
          reset.current = itemReset;
        }

        Locale();
        setNavigation({view: 'login'});

        window.history.replaceState(null, "SmartDocFitness", "/");
        document.title = 'SmartDocFitness';

        Width();
        window.addEventListener('resize', Width);
        return () => window.removeEventListener('resize', Width);
      }, []);

  return (
      <NetworkProvider locale={locale} session={session.current} token={token.current}>
        <Page theme="ios" themeVariant="light">
          {(navigation.view === 'login' || navigation.view === 'register') &&
              <Header
                  locale={locale}
                  displayName={displayName.current}
                  dialog={dialog}
                  Dialog={Dialog}
                  configuration={configuration}
                  setLocale={setLocale}
                  width={width}
                  Navigation={Navigation}
                  navigation={navigation}
              />
          }
          {navigation.view === 'login' &&
              <Login
                  token={token}
                  locale={locale}
                  Navigation={Navigation}
                  setNavigation={setNavigation}
                  dialog={dialog}
                  Dialog={Dialog}
                  setDialog={setDialog}
                  reset={reset}
                  account={account}
                  configuration={configuration}
                  setConfiguration={setConfiguration}
                  logo={logo}
                  setOptions={setOptions}
                  displayName={displayName}
                  setLocale={setLocale}
              />
          }
          {!Empty(configuration) && locale.register && navigation.view === 'register' &&
              <Register
                  locale={locale}
                  Navigation={Navigation}
                  account={account}
                  width={width}
              />
          }
          {!Empty(configuration) && navigation.view === 'home' &&
              <Home
                  locale={locale}
                  navigation={navigation}
                  Navigation={Navigation}
                  user={user}
                  width={width}
                  configuration={configuration}
                  date={date}
                  setDate={setDate}
                  setLocale={setLocale}
                  setUser={setUser}
                  displayName={displayName.current}
                  dialog={dialog}
                  Dialog={Dialog}
                  session={session.current}
                  options={options}
                  token={token}
              />
          }
        </Page>
      </NetworkProvider>
  );
}

export default App;
