import React, {useEffect, useState, useCallback, useContext} from 'react';
import {Toast} from "@mobiscroll/react";
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import 'dayjs/locale/es'
import './styles/home.css';
import Empty from './utils/Empty';
import HomeHeader from "./blocks/HomeHeader";
import Data from "./pages/Data";
import Device from "./pages/Device";
import Profile from "./pages/Profile";
import Help from "./pages/Help";
import HomeFooter from "./blocks/HomeFooter";
import {NetworkContext} from "./utils/NetworkContext";

function Home({locale, navigation, Navigation, user, width, configuration, date, setDate, setLocale, setUser, displayName, dialog, Dialog, session, options, token}) {
    const {restCall, restResponse, setRestResponse} = useContext(NetworkContext);
    const [devices, setDevices] = useState([]);
    const [refresh, setRefresh] = useState(false);
    const [toast, setToast] = useState({});

    const Start = () => {
        setToast({message: locale.home.a, color: 'info', display: 'bottom', duration: 1000});

        const message = {
            type: 'rest',
            path: 'user-login',
            action: 'get',
            data: {}
        };
        restCall(message);
    }

    const Logout = useCallback(() => {
        window.localStorage.removeItem('token');
        token.current = '';
        setUser({});
        Navigation({view: 'login'});

        setToast({message: locale.app.a, color: 'info', display: 'bottom', duration: 1000});
    }, [locale.app.a, Navigation]);

    const GetUserLogin = useCallback(data => {
        if (data.user && data.user.locale === 'en') {
            setLocale(configuration.locale.en);
        }
        if (data.user && data.user.locale === 'es') {
            setLocale(configuration.locale.es);
        }
        if (data.user) {
            setUser(data.user);
            window.localStorage.locale = data.user.locale;
            Navigation({page: 'data', tab: 'day', metric: 'activity', item: 'steps'});
        }
    }, [configuration, Navigation]);

    useEffect(() => {
        if (restResponse && !Empty(restResponse) && restResponse.action === 'get' && restResponse.path === 'user-login') {
            GetUserLogin(restResponse.data);
            setRestResponse({});
        }
    }, [restResponse, GetUserLogin, setRestResponse]);

    useEffect(() => {
        Start();
    }, []);

  return (
    <>
        <HomeHeader
            navigation={navigation}
            Navigation={Navigation}
            displayName={displayName}
            user={user}
            Dialog={Dialog}
            dialog={dialog}
            configuration={configuration}
            width={width}
            options={options}
            locale={locale}
            Logout={Logout}
            setLocale={setLocale}
            setUser={setUser}
            date={date}
            toast={toast}
            devices={devices}
            setDevices={setDevices}
            setRefresh={setRefresh}
            setToast={setToast}
        />
        {!Empty(user) && navigation.page === 'data' &&
            <Data
                locale={locale}
                navigation={navigation}
                Navigation={Navigation}
                user={user}
                width={width}
                configuration={configuration}
                date={date}
                setDate={setDate}
                setLocale={setLocale}
                setUser={setUser}
                displayName={displayName}
                setToast={setToast}
                toast={toast}
                dialog={dialog}
                Dialog={Dialog}
            />
        }
        {!Empty(user) && navigation.page === 'device' &&
            <Device
                locale={locale}
                configuration={configuration}
                devices={devices}
                setDevices={setDevices}
                session={session}
                toast={toast}
                setToast={setToast}
                refresh={refresh}
                width={width}
            />
        }
        {!Empty(user) && navigation.page === 'profile' &&
            <Profile
                locale={locale}
                user={user}
                setUser={setUser}
                navigation={navigation}
                Navigation={Navigation}
                width={width}
                setToast={setToast}
            />
        }
        {!Empty(user) && navigation.page === 'help' &&
            <Help
                locale={locale}
                Navigation={Navigation}
                setToast={setToast}
                width={width}
            />
        }
        <HomeFooter
            locale={locale}
            navigation={navigation}
            Navigation={Navigation}
            toast={toast}
        />
        <Toast
            theme="ios"
            themeVariant="light"
            isOpen={!Empty(toast)}
            message={toast.message}
            duration={toast.duration}
            color={toast.color}
            display={toast.display}
            onClose={() => setToast({})}
        />
    </>
  );
}

export default Home;
