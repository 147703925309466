import React, {useEffect, useState, useCallback, useContext} from 'react';
import {Button, Select, Textarea} from "@mobiscroll/react";
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import '../styles/help.css';
import Empty from '../utils/Empty';
import {NetworkContext} from "../utils/NetworkContext";
import {faPaperPlane} from "@fortawesome/pro-duotone-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

function Help({locale, Navigation, setToast, width}) {
    const {restCall, restResponse, setRestResponse} = useContext(NetworkContext);
    const [supportText, setSupportText] = useState('');
    const [supportPriority, setSupportPriority] = useState('low');

    const Help = useCallback(() => {
        if (!supportText) {
            setToast({ message: locale.help.g, color: 'warning', display: 'bottom', duration: 3000});
        } else {
            setToast({ message: locale.help.h, duration: 1000, color: 'primary', display: 'bottom'});

            const message = {
                type: 'rest',
                path: 'help',
                action: 'put',
                data: {
                    text: supportText,
                    priority: supportPriority
                }
            };
            restCall(message);
        }
    }, [restCall, supportText, supportPriority, locale.help.g, locale.help.h]);

    const PutHelp = useCallback(data => {
        if (data.toast) {
            setSupportText('');
            setSupportPriority('low');
            Navigation({page: 'profile', tab: 'account'});
            setToast({ message: locale.help.i, color: 'primary', display: 'bottom', duration: 1000});
        }
    }, [Navigation, locale.help.i]);

    useEffect(() => {
        if (!Empty(restResponse) && restResponse.action === 'put' && restResponse.path === 'help') {
            PutHelp(restResponse.data);
            setRestResponse({});
        }
    }, [restResponse, PutHelp, setRestResponse])

    return (
        <div className={width > 768 ? "mbsc-grid cs-help-grid" : "mbsc-grid cs-help-grid-small"}>
            <div className="mbsc-row mbsc-padding cs-help-main">
                <Button color="primary" variant="flat" theme="ios" themeVariant="light" className="cs-help-button mbsc-bold" onClick={() => Help()} >
                    <FontAwesomeIcon className="cs-help-button-icon" icon={faPaperPlane} />{locale.help.a}
                </Button>
            </div>
            <div className="mbsc-row cs-help-main">
                <div className="mbsc-col cs-help-col">
                    <Select
                        theme="ios"
                        themeVariant="light"
                        animation="slide-down"
                        inputStyle="outline"
                        label={locale.help.b}
                        labelStyle="floating"
                        touchUi={false}
                        rows={locale.help.j.length}
                        display="anchored"
                        data={locale.help.j}
                        value={supportPriority}
                        onChange={(event) => setSupportPriority(event.value)}
                    />
                </div>
            </div>
            <div className="mbsc-row cs-help-main">
                <div className="mbsc-col cs-help-col">
                    <Textarea
                        theme="ios"
                        themeVariant="light"
                        inputStyle="outline"
                        labelStyle="floating"
                        label={locale.help.f}
                        value={supportText}
                        onChange={(event) => setSupportText(event.value)}
                    />
                </div>
            </div>
        </div>
    );
}

export default Help;
