import React, {useState, useContext} from 'react';
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import '../styles/profileAccount.css';
import {NetworkContext} from "../utils/NetworkContext";
import {Input, Select} from "@mobiscroll/react";

function ProfileAccount({locale, user, setToast}) {
    const {restCall} = useContext(NetworkContext);
    const [alias, setAlias] = useState(user.alias ? user.alias : null);
    const [firstName, setFirstName] = useState(user.firstName ? user.firstName : null);
    const [lastName, setLastName] = useState(user.lastName ? user.lastName : null);
    const [email, setEmail] = useState(user.email ? user.email : null);
    const [country, setCountry] = useState(() => {
        if (user.mobile.startsWith('+1')) {
            return 'us';
        } else if (user.mobile.startsWith('+52')) {
            return 'mx';
        } else {
            return 'us';
        }
    });
    const [mobile, setMobile] = useState(() => {
        if (user.mobile.startsWith('+1')) {
            const number = user.mobile.slice(2);

            return `(${number.slice(0, 3)}) ${number.slice(3, 6)}-${number.slice(6, 10)}`;
        } else if (user.mobile.startsWith('+52')) {
            const number = user.mobile.slice(3);

            return `(${number.slice(0, 2)}) ${number.slice(2, 6)}-${number.slice(6, 10)}`;
        } else {
            return user.mobile;
        }
    });

    const Mobile = data => {
        let formattedText = '';

        if (country === 'us') {
            const cleanedText = data.replace(/[^\d]/g, '');

            if (cleanedText.length <= 3) {
                formattedText = cleanedText;
            } else if (cleanedText.length <= 6) {
                formattedText = `(${cleanedText.slice(0, 3)}) ${cleanedText.slice(3)}`;
            } else {
                formattedText = `(${cleanedText.slice(0, 3)}) ${cleanedText.slice(3, 6)}-${cleanedText.slice(6, 10)}`;
            }
        } else if (country === 'mx') {
            const cleanedText = data.replace(/[^\d]/g, '');

            if (cleanedText.length <= 2) {
                formattedText = cleanedText;
            } else if (cleanedText.length <= 6) {
                formattedText = `(${cleanedText.slice(0, 2)}) ${cleanedText.slice(2)}`;
            } else {
                formattedText = `(${cleanedText.slice(0, 2)}) ${cleanedText.slice(2, 6)}-${cleanedText.slice(6, 10)}`;
            }
        }
        setMobile(formattedText);
    }

    const Save = () => {
        setToast({message: locale.profileAccount.g, color: 'info', display: 'bottom', duration: 1000});

        let itemMobile = '';

        if (mobile) {
            itemMobile = mobile.replace(/[()\s-]/g, '');
        }

        const item = {
            firstName: firstName ? firstName : null,
            lastName: lastName ? lastName : null,
            alias: alias ? alias : null,
            email: email ? email : null,
            mobile: itemMobile ? itemMobile : null,
            country: country
        }

        const message = {
            type: 'rest',
            path: 'user',
            action: 'update',
            data: item
        };
        restCall(message);
    }

  return (
      <div className="mbsc-row cs-profile-account-main">
          <div className="mbsc-col">
              <div className="cs-profile-account-helper">
                  <p>{locale.profileAccount.e}</p>
              </div>
              <div className="cs-profile-account-helper">
                  <p>{locale.profileAccount.f}</p>
              </div>
              {user.type === 'email' &&
                  <>
                      <Input disabled theme="ios" themeVariant="light" inputStyle="outline" label={locale.profileAccount.h} labelStyle="floating" type="email" name="Email" value={user.id} />
                      <div className="mbsc-row cs-profile-account-mobile-row">
                          <div className="mbsc-col-4 cs-profile-account-inputs-left">
                              <Select
                                  theme="ios"
                                  themeVariant="light"
                                  animation="slide-down"
                                  dropdown={false}
                                  inputStyle="outline"
                                  label={locale.profileAccount.a}
                                  labelStyle="floating"
                                  rows={locale.profileAccount.b.length}
                                  display="anchored"
                                  touchUi={false}
                                  value={country}
                                  data={locale.profileAccount.b}
                                  disabled={mobile.length !== 0}
                                  onChange={(event) => setCountry(event.value)}
                              />
                          </div>
                          <div className="mbsc-col-8 cs-profile-account-inputs-right">
                              <Input theme="ios" themeVariant="light" className="cs-profile-account-inputs-right-input" label={locale.profileAccount.l} inputStyle="outline" labelStyle="floating" type="text" name="mobile" value={mobile} onChange={ev => Mobile(ev.target.value)} onBlur={() => Save()} />
                          </div>
                      </div>
                  </>
              }
              {user.type === 'mobile' &&
                  <>
                      <Input disabled theme="ios" themeVariant="light" inputStyle="outline" label={locale.profileAccount.l} labelStyle="floating" type="text" name="Mobile" value={user.id} />
                      <Input theme="ios" themeVariant="light" inputStyle="outline" label={locale.profileAccount.h} labelStyle="floating" type="email" name="Email" value={email} onChange={ev => setEmail(ev.target.value)} onBlur={() => Save()} />
                  </>
              }
              {user.type === 'username' &&
                  <>
                      <Input disabled theme="ios" themeVariant="light" inputStyle="outline" label={locale.profileAccount.m} labelStyle="floating" type="text" name="Username" value={user.id} />
                      <Input theme="ios" themeVariant="light" inputStyle="outline" label={locale.profileAccount.h} labelStyle="floating" type="email" name="Email" value={email} onChange={ev => setEmail(ev.target.value)} onBlur={() => Save()} />
                      <div className="mbsc-row cs-profile-account-mobile-row">
                          <div className="mbsc-col-4 cs-profile-account-inputs-left">
                              <Select
                                  theme="ios"
                                  themeVariant="light"
                                  animation="slide-down"
                                  dropdown={false}
                                  inputStyle="outline"
                                  label={locale.profileAccount.a}
                                  labelStyle="floating"
                                  rows={locale.profileAccount.b.length}
                                  display="anchored"
                                  touchUi={false}
                                  value={country}
                                  data={locale.profileAccount.b}
                                  disabled={mobile.length !== 0}
                                  onChange={(event) => setCountry(event.value)}
                              />
                          </div>
                          <div className="mbsc-col-8 cs-profile-account-inputs-right">
                              <Input theme="ios" themeVariant="light" className="cs-profile-account-inputs-right-input" label={locale.profileAccount.l} inputStyle="outline" labelStyle="floating" type="text" name="mobile" value={mobile} onChange={ev => Mobile(ev.target.value)} onBlur={() => Save()} />
                          </div>
                      </div>
                  </>
              }
              <Input theme="ios" themeVariant="light" inputStyle="outline" label={locale.profileAccount.i} labelStyle="floating" type="text" name="Nickname" value={alias} onChange={ev => setAlias(ev.target.value)} onBlur={() => Save()} />
              <Input theme="ios" themeVariant="light" inputStyle="outline" label={locale.profileAccount.j} labelStyle="floating" type="text" name="First Name" value={firstName} onChange={ev => setFirstName(ev.target.value)} onBlur={() => Save()} />
              <Input theme="ios" themeVariant="light" inputStyle="outline" label={locale.profileAccount.k} labelStyle="floating" type="text" name="Last Name" value={lastName} onChange={ev => setLastName(ev.target.value)} onBlur={() => Save()} />
          </div>
      </div>
  );
}

export default ProfileAccount;
