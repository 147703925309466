import React, {useState, useContext, useEffect} from 'react';
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import '../styles/profileInformation.css';
import {NetworkContext} from "../utils/NetworkContext";
import {Datepicker, Input, Select} from "@mobiscroll/react";
import dayjs from "dayjs";

function ProfileInformation({locale, user, width, setToast}) {
    const {restCall} = useContext(NetworkContext);
    const [gender, setGender] = useState(user.gender);
    const [dob, setDob] = useState(user.dob);
    const [unit, setUnit] = useState(user.unit);
    const [height, setHeight] = useState(user.height);
    const [toSave, setToSave] = useState(false);

    const Save = () => {
        setToast({message: locale.profileInformation.q, color: 'info', display: 'bottom', duration: 1000});

        const message = {
            type: 'rest',
            path: 'user',
            action: 'update',
            data: {
                email: user.email,
                gender: gender,
                dob: dob,
                unit: unit,
                height: height,
            }
        };
        restCall(message);
    }

    const Height = data => {
        const re = /^[0-9\b]+$/;

        if (data === "" || (re.test(data) && (data <= 300))) {
            setHeight(data);
        }
    }

    useEffect(() => {
        if (toSave) {
            Save();
            setToSave(false);
        }
    }, [toSave, Save]);

  return (
      <div className="mbsc-row cs-profile-information-main">
          <div className="mbsc-col">
              <div className="cs-profile-information-helper">
                  <p>{locale.profileInformation.e}</p>
              </div>
              <div className="cs-profile-information-helper">
                  <p>{locale.profileInformation.f}</p>
              </div>
              <Select
                  theme="ios"
                  themeVariant="light"
                  animation="slide-down"
                  inputStyle="outline"
                  label={locale.profileInformation.n}
                  labelStyle="floating"
                  touchUi={false}
                  rows={locale.profileInformation.a.length}
                  display="anchored"
                  data={locale.profileInformation.a}
                  value={unit}
                  onChange={(event) => {setUnit(event.value); setToSave(true);}}
              />
              <Select
                  theme="ios"
                  themeVariant="light"
                  animation="slide-down"
                  inputStyle="outline"
                  label={locale.profileInformation.h}
                  labelStyle="floating"
                  touchUi={false}
                  rows={locale.profileInformation.b.length}
                  display="anchored"
                  data={locale.profileInformation.b}
                  value={gender}
                  onChange={(event) => {setGender(event.value); setToSave(true);}}
              />
              <Input theme="ios" themeVariant="light" inputStyle="outline" label={unit === 'metric' ? locale.profileInformation.k : locale.profileInformation.l} labelStyle="floating" type="text" name="Height" value={height} onChange={ev => Height(ev.target.value)} onBlur={() => Save()} />
              <Datepicker
                  theme="ios"
                  themeVariant="light"
                  controls={['date']}
                  touchUi={width > 576 ? false : true}
                  inputStyle="outline"
                  label={locale.profileInformation.m}
                  labelStyle="floating"
                  value={dob}
                  selectMultiple={false}
                  max={dayjs().format("YYYY-MM-DD")}
                  onChange={(event) => setDob(event.value)}
                  onClose={() => Save()}
              />
          </div>
      </div>
  );
}

export default ProfileInformation;
