import React, {useContext, useRef} from 'react';
import {Datepicker, Select, Button} from "@mobiscroll/react";
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import '../styles/dataCalendar.css';
import dayjs from 'dayjs';
import 'dayjs/locale/es'
import Empty from "../utils/Empty";
import Loading from "../utils/Loading";
import {NetworkContext} from "../utils/NetworkContext";

function DataCalendar({user, setDate, navigation, mobiMonth, mobiYear, locale, date, setToast, toast}) {
  const {restCall} = useContext(NetworkContext);
  const today = useRef(dayjs().format('YYYY-MM-DD'));

  const Activity = data => {
    setToast({message: locale.dataCalendar.a, color: 'info', display: 'bottom', duration: 1000});

    const message = {
      type: 'rest',
      path: 'activity',
      action: 'get',
      data: {
        date: data,
        range: navigation.tab,
        metric: navigation.item,
        block: 'dataCalendar'
      }
    };
    restCall(message);
  }

  const HR = data => {
    setToast({message: locale.dataCalendar.a, color: 'info', display: 'bottom', duration: 1000});

    const message = {
      type: 'rest',
      path: 'hr',
      action: 'get',
      data: {
        date: data,
        metric: navigation.item,
        block: 'dataCalendar'
      }
    };
    restCall(message);
  }

  const Sleep = data => {
    setToast({message: locale.dataCalendar.a, color: 'info', display: 'bottom', duration: 1000});

    const message = {
      type: 'rest',
      path: 'sleep',
      action: 'get',
      data: {
        date: data,
        block: 'dataCalendar'
      }
    };
    restCall(message);
  }

  const Day = data => {
    const item = dayjs(data.value).format('YYYY-M-D')
    setDate(item);
    if (navigation.metric === 'activity') {
      Activity(item);
    } else if (navigation.metric === 'hr') {
      HR(item);
    } else if (navigation.metric === 'sleep') {
      Sleep(item);
    }
  }

  const Month = data => {
    if (data) {
      setDate(data);
      Activity(data);
    }
  }

  const Year = data => {
    setDate(data);
    Activity(data);
  }

  return (
      <div className="mbsc-row mbsc-justify-content-center cs-data-calendar-main cs-data-calendar-input cs-data-calendar-select">
        {(navigation.tab === 'day' || navigation.tab === 'week') &&
            <Datepicker
                themeVariant="light"
                value={dayjs(date).format('YYYY-MM-DD')}
                select="date"
                returnFormat="iso8601"
                dateFormat="MMM D, YYYY"
                locale={user.locale}
                onChange={Day}
                display="bubble"
                max={today.current}
                inputStyle="box"
                touchUi={false}>
            </Datepicker>
        }
        {navigation.tab === 'month' && mobiMonth.length !== 0 &&
            <Select
                theme="ios"
                themeVariant="light"
                animation="slide-down"
                dropdown={false}
                inputStyle="box"
                rows={mobiMonth.length > 10 ? 10 : mobiMonth.length}
                defaultSelection={mobiMonth[mobiMonth.length - 1].value}
                display="anchored"
                touchUi={false}
                value={date}
                data={mobiMonth}
                onChange={(event) => Month(event.value)}
            />
        }
        {navigation.tab === 'year' && mobiYear.length !== 0 &&
            <Select
                theme="ios"
                themeVariant="light"
                animation="slide-down"
                dropdown={false}
                inputStyle="box"
                rows={mobiYear.length > 10 ? 10 : mobiYear.length}
                defaultSelection={mobiYear[mobiYear.length - 1].value}
                display="anchored"
                touchUi={false}
                value={date}
                data={mobiYear}
                onChange={(event) => Year(event.value)}
            />
        }
        {Empty(toast) && ((navigation.tab === 'month' && mobiMonth.length === 0) || (navigation.tab === 'year' && mobiYear.length === 0)) &&
            <Button color="primary" variant="flat" theme="ios" themeVariant="light" className="cs-data-calendar-button mbsc-bold" >
              {locale.dataCalendar.d}
            </Button>
        }
        {!Empty(toast) && (navigation.tab === 'month'|| navigation.tab === 'year') &&
            <Loading />
        }
      </div>
  );
}

export default DataCalendar;
