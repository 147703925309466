import React, {useContext} from 'react';
import {SegmentedGroup, Segmented} from "@mobiscroll/react";
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import '../styles/dataChallengeRange.css';
import {NetworkContext} from "../utils/NetworkContext";
import dayjs from "dayjs";

function DataChallengeRange({locale, navigation, Navigation, width, setDate, setToast}) {
  const {restCall} = useContext(NetworkContext);

    const Challenge = data => {
        const itemDate = dayjs().format('YYYY-M-D');
        setDate(itemDate);
        setToast({message: locale.dataChallengeRange.g, color: 'info', display: 'bottom', duration: 1000});
        Navigation({tab: data.target.value});

        const message = {
            type: 'rest',
            path: 'challenge',
            action: 'get',
            data: {
                date: itemDate,
                range: data.target.value,
                challenge: navigation.item
            }
        };
        restCall(message);
    }

    return (
        <SegmentedGroup theme="ios" themeVariant="light" className="cs-data-challenge-range-nav" name="dataChallengeRange" color="primary" value={navigation.tab} onChange={Challenge}>
            <Segmented value="day" theme="ios" themeVariant="light" >
                {width > 768 ? locale.dataChallengeRange.a : locale.dataChallengeRange.d}
            </Segmented>
            <Segmented value="week" theme="ios" themeVariant="light" >
                {width > 768 ? locale.dataChallengeRange.b : locale.dataChallengeRange.e}
            </Segmented>
            <Segmented value="month" theme="ios" themeVariant="light" >
                {width > 768 ? locale.dataChallengeRange.c : locale.dataChallengeRange.f}
            </Segmented>
        </SegmentedGroup>
    );
}

export default DataChallengeRange;
