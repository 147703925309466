import React from 'react';
import {Button} from "@mobiscroll/react";
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import '../styles/dataSleep.css';

function DataSleep({locale, sleep}) {

  return (
      <>
          <div className="mbsc-row cs-data-sleep-main cs-data-sleep-start mbsc-justify-content-center mbsc-txt-muted">
              {(sleep.total.hours !== 0 || sleep.total.minutes !== 0)&&
                  <h4>
                      Total:&nbsp;
                  </h4>
              }
              {sleep.total.hours !== 0 &&
                  <h4>
                      {sleep.total.hours} {locale.dataSleep.f}
                  </h4>
              }
              {sleep.total.minutes !== 0 &&
                  <h4>
                      &nbsp;{sleep.total.minutes} {locale.dataSleep.g}
                  </h4>
              }
              {sleep.total.hours === 0 && sleep.total.minutes === 0 &&
                  <Button color="secondary" variant="flat" theme="ios" themeVariant="light" className="mbsc-bold cs-data-sleep-cursor" >
                      {locale.dataSleep.h}
                  </Button>
              }
          </div>
          {(sleep.one.hours !== 0 || sleep.one.minutes !== 0) &&
              <div className="mbsc-row cs-data-sleep-main mbsc-justify-content-center mbsc-txt-muted">
                  {(sleep.one.hours !== 0 || sleep.one.minutes !== 0) &&
                      <h4 className="cs-data-sleep-one">
                          {locale.dataSleep.a}:&nbsp;
                      </h4>
                  }
                  {sleep.one.hours !== 0 &&
                      <h4>
                          {sleep.one.hours} {locale.dataSleep.f}
                      </h4>
                  }
                  {sleep.one.minutes !== 0 &&
                      <h4>
                          &nbsp;{sleep.one.minutes} {locale.dataSleep.g}
                      </h4>
                  }
              </div>
          }
          {(sleep.two.hours !== 0 || sleep.two.minutes !== 0) &&
              <div className="mbsc-row cs-data-sleep-main mbsc-justify-content-center mbsc-txt-muted">
                  {(sleep.two.hours !== 0 || sleep.two.minutes !== 0) &&
                      <h4 className="cs-data-sleep-two">
                          {locale.dataSleep.b}:&nbsp;
                      </h4>
                  }
                  {sleep.two.hours !== 0 &&
                      <h4>
                          {sleep.two.hours} {locale.dataSleep.f}
                      </h4>
                  }
                  {sleep.two.minutes !== 0 &&
                      <h4>
                          &nbsp;{sleep.two.minutes} {locale.dataSleep.g}
                      </h4>
                  }
              </div>
          }
          {(sleep.three.hours !== 0 || sleep.three.minutes !== 0) &&
              <div className="mbsc-row cs-data-sleep-main mbsc-justify-content-center mbsc-txt-muted">
                  {(sleep.three.hours !== 0 || sleep.three.minutes !== 0) &&
                      <h4 className="cs-data-sleep-three">
                          {locale.dataSleep.c}:&nbsp;
                      </h4>
                  }
                  {sleep.three.hours !== 0 &&
                      <h4>
                          {sleep.three.hours} {locale.dataSleep.f}
                      </h4>
                  }
                  {sleep.three.minutes !== 0 &&
                      <h4>
                          &nbsp;{sleep.three.minutes} {locale.dataSleep.g}
                      </h4>
                  }
              </div>
          }
          {(sleep.four.hours !== 0 || sleep.four.minutes !== 0) &&
              <div className="mbsc-row cs-data-sleep-main mbsc-justify-content-center mbsc-txt-muted">
                  {(sleep.four.hours !== 0 || sleep.four.minutes !== 0) &&
                      <h4 className="cs-data-sleep-four">
                          {locale.dataSleep.d}:&nbsp;
                      </h4>
                  }
                  {sleep.four.hours !== 0 &&
                      <h4>
                          {sleep.four.hours} {locale.dataSleep.f}
                      </h4>
                  }
                  {sleep.four.minutes !== 0 &&
                      <h4>
                          &nbsp;{sleep.four.minutes} {locale.dataSleep.g}
                      </h4>
                  }
              </div>
          }
          {(sleep.five.hours !== 0 || sleep.five.minutes !== 0) &&
              <div className="mbsc-row cs-data-sleep-main mbsc-justify-content-center mbsc-txt-muted">
                  {(sleep.five.hours !== 0 || sleep.five.minutes !== 0) &&
                      <h4 className="cs-data-sleep-five">
                          {locale.dataSleep.e}:&nbsp;
                      </h4>
                  }
                  {sleep.five.hours !== 0 &&
                      <h4>
                          {sleep.five.hours} {locale.dataSleep.f}
                      </h4>
                  }
                  {sleep.five.minutes !== 0 &&
                      <h4>
                          &nbsp;{sleep.five.minutes} {locale.dataSleep.g}
                      </h4>
                  }
              </div>
          }
      </>
  );
}

export default DataSleep;
