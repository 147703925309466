import React, {useEffect, useCallback, useContext} from 'react';
import {SegmentedGroup, Segmented, Button} from "@mobiscroll/react";
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import '../styles/profile.css';
import Empty from '../utils/Empty';
import ProfileAccount from '../blocks/ProfileAccount';
import ProfileInformation from '../blocks/ProfileInformation';
import ProfilePreferences from '../blocks/ProfilePreferences';
import {NetworkContext} from "../utils/NetworkContext";

function Profile({locale, user, setUser, navigation, Navigation, width, setToast}) {
    const {restCall, restResponse, setRestResponse} = useContext(NetworkContext);

    const Tab = data => {
        Navigation({tab: data.target.value});
    }

    const Link = () => {
        window.open('https://en.gravatar.com', '_blank');
    }

    const User = useCallback(() => {
        const message = {
            type: 'rest',
            path: 'user',
            action: 'get',
            data: {}
        };
        restCall(message);
    }, [restCall]);

    const GetUser = useCallback(data => {
        if (data.user) {
            setUser(data.user);
        }
    }, []);

    const UpdateUser = useCallback(data => {
        if (data.toast && navigation.page === 'profile') {
            User();
        }
    }, [User, navigation.page]);

    useEffect(() => {
        if (!Empty(restResponse) && restResponse.action === 'update' && restResponse.path === 'user') {
            UpdateUser(restResponse.data);
            setRestResponse({});
        }
    }, [restResponse, UpdateUser, setRestResponse])

    useEffect(() => {
        if (!Empty(restResponse) && restResponse.action === 'get' && restResponse.path === 'user') {
            GetUser(restResponse.data);
            setRestResponse({});
        }
    }, [restResponse, GetUser, setRestResponse])

    return (
        <div className={width > 768 ? "mbsc-grid cs-profile-grid" : "mbsc-grid cs-profile-grid-small"}>
            <div className="mbsc-row cs-profile-main mbsc-justify-content-center">
                {user.avatar ?
                    <div style={{backgroundImage: "url('https://www.gravatar.com/avatar/" + user.avatar + "?d=robohash')"}} className="cs-profile-avatar" />
                    :
                    <div style={{backgroundImage: "url('https://www.gravatar.com/avatar/" + (+ new Date()) + "?d=robohash')"}} className="cs-profile-avatar" />
                }
            </div>
            <div className="mbsc-row cs-profile-gravatar cs-profile-main mbsc-justify-content-center">
                <Button color="primary" variant="flat" theme="ios" themeVariant="light" className="cs-profile-button" onClick={() => Link()} >
                    {locale.profile.d}
                </Button>
            </div>
            <div className="mbsc-row cs-profile-main">
                <div className="mbsc-col cs-profile-col">
                    <SegmentedGroup theme="ios" themeVariant="light" className="cs-profile-nav" name="profile" color="primary" value={navigation.tab} onChange={Tab}>
                        <Segmented value="account" theme="ios" themeVariant="light" >
                            {locale.profile.a}
                        </Segmented>
                        <Segmented value="information" theme="ios" themeVariant="light" >
                            {locale.profile.b}
                        </Segmented>
                        <Segmented value="preferences" theme="ios" themeVariant="light" >
                            {locale.profile.c}
                        </Segmented>
                    </SegmentedGroup>
                </div>
            </div>
            {navigation.tab === "account" &&
                <ProfileAccount
                    locale={locale}
                    user={user}
                    setToast={setToast}
                />
            }
            {navigation.tab === "information" &&
                <ProfileInformation
                    locale={locale}
                    user={user}
                    width={width}
                    setToast={setToast}
                />
            }
            {navigation.tab === "preferences" &&
                <ProfilePreferences
                    locale={locale}
                    user={user}
                    setToast={setToast}
                />
            }
        </div>
    );
}

export default Profile;
