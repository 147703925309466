import React, {useCallback, useState, useContext} from 'react';
import {Popup, toast, Input} from "@mobiscroll/react";
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import '../styles/dataChallengeNew.css';
import {NetworkContext} from "../utils/NetworkContext";

function DataChallengeNew({dialog, Dialog, locale, width}) {
    const {restCall} = useContext(NetworkContext);
    const [challenge, setChallenge] = useState('');

  const CloseDialog = useCallback(() => {
    Dialog({view: 'close'});
  }, [Dialog]);

  const Join = () => {
    if (!challenge) {
      toast({message: locale.dataChallengeNew.a, color: 'danger', display: 'bottom', duration: 3000});
    } else {
        const message = {
            type: 'rest',
            path: 'challenge-user',
            action: 'put',
            data: {
                challenge: challenge
            }
        };
        restCall(message);
    }
  }

  return (
      <Popup className="cs-data-challenge-new-popup" width={600} theme="ios" themeVariant="light" display="center" headerText={locale.dataChallengeNew.e} buttons={[{text: locale.dataChallengeNew.d, cssClass: 'cs-data-challenge-new-popup-save', handler: function (event) {Join()}}]} isOpen={dialog.view === 'dataChallengeNew'} onClose={CloseDialog}>
          <div className="cs-data-challenge-new-helper">
              <p>{locale.dataChallengeNew.b}</p>
          </div>
          <Input theme="ios" themeVariant="light" inputStyle="underline" labelStyle="floating" type="text" name="challenge" label={locale.dataChallengeNew.c} value={challenge} onChange={ev => setChallenge(ev.target.value)} />
      </Popup>
  );
}

export default DataChallengeNew;
