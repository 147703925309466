import React, {useMemo} from 'react';
import REST from "./REST";
import {NetworkContext} from './NetworkContext';

export const NetworkProvider = ({children, token, session, locale}) => {
    const {restResponse, restCall, setRestResponse} = REST(locale, token, session);

    const contextValue = useMemo(() => ({
        restCall,
        restResponse,
        setRestResponse
    }), [restCall, restResponse, setRestResponse]);

    return (
        <NetworkContext.Provider value={contextValue}>
            {children}
        </NetworkContext.Provider>
    );
};
